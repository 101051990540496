const styles = {
  '& ul': {
    flexDirection: 'column',
    listStyle: 'none',
  },
  '& ul li::before': {
    content: '"\u2022"',
    color: '#ccc',
    fontWeight: 'bold',
    display: 'inline-block',
    width: '1em',
    marginLeft: '-1em',
  },
  '& ul li b': {
    color: '#eee',
  },
}

export default styles
