import { Theme } from '@/app'
import { React, View, Text, CenterWrapper, Image, Button } from '@/lib'
import { data } from './_data'
import { Reveal, ScrollDown } from '@/components'
import { Tilt } from '@jdion/tilt-react'
import tweenProps from '@/components/Reveal/tweenProps'
import { Tools } from '@/lib'

export const PartnerCompanies = () => {

  const { height } = Theme.hooks.size()

  const limitedPerformance = Tools.useLimitedPerformance()
  const downxlarge = Theme.hooks.down('xlarge')

  const title = `Join the world’s biggest organisations using our digital products.`
  const subtitleText = downxlarge ? (
    <>
      {' '}
      Ready to build your innovative solution? <br/> Contact us for a chat.{' '}
    </>
  ) : (
    <> Ready to build your innovative solution? Contact us for a chat. </>
  )

  const scrollDownHref = '#services'

  return (
    <View style={styles.wrapper} variant={'relative'} id={'partnerCompanies'}>
      <CenterWrapper>
        <Reveal
          variant={'fixedBackground fadeInOut'}
          offset={height * -0.3}
          duration={height * 1.05}
          disabled={limitedPerformance > 0}
          fixedBackgroundOpacity={tweenProps.fixedBackgroundFullOpacity}
        >
          <View style={styles.innerWrapper} variant={'flex'}>
            <View
              variant={'flex column marginTop:2'}
              style={styles.headerWrapper}
            >
              <Text
                variant={`h3 white marginBottom:5`}
                style={styles.title}
                text={title}
              />
              <Text
                variant={`p1 white marginBottom:5`}
                style={styles.subtitle}
                text={subtitleText}
              />
              <Button
                openNewTab
                gaLabel={'Portfolio'}
                gaAction={`Navigate to /get-a-quote`}
                variant={'inverted'}
                text={'Get in touch'}
                to={'/get-a-quote'}
                style={styles.getAQuoteButton}
              />
            </View>

            <View variant={'flex'}>
              <View style={styles.grid}>
                {data.map((item) => {
                  return (
                    <Tilt
                      key={item}
                      options={{ scale: 1.3, max: 15, speed: 3000 }}
                    >
                      <Image
                        alt={'company logo'}
                        source={item}
                        style={styles.logo}
                      />
                    </Tilt>
                  )
                })}
              </View>
            </View>
          </View>
        </Reveal>
      </CenterWrapper>

      <View up={'mid'} variant={'marginTop:15 fullWidth'}>
        <Reveal
          variant={'exitOpacity'}
          offset={height * 0.1}
          duration={height * 0.33}
          disabled={limitedPerformance > 0}
        >
          <div>
            <ScrollDown
              color={Theme.colors.offwhite}
              gaLabel={'PartnerCompanies'}
              href={scrollDownHref}
            />
          </div>
        </Reveal>
      </View>
    </View>
  )
}

export default PartnerCompanies

const styles = {
  wrapper: {
    paddingTop: Theme.spacing(12),
    marginBottom: Theme.spacing(12),
    [Theme.media.down('largeish')]: {
      paddingTop: Theme.spacing(40),
      paddingBottom: Theme.spacing(10),
    },
    [Theme.media.down('mid')]: {
      paddingTop: Theme.spacing(10),
      marginBottom: Theme.spacing(5),
    },
  },
  innerWrapper: {
    [Theme.media.down('mid')]: {
      flexDirection: 'column',
    },
  },
  headerWrapper: {
    [Theme.media.down('small')]: {
      ...Theme.center,
    },
  },
  title: {
    maxWidth: '80%',
    [Theme.media.down('large')]: {
      maxWidth: '90%',
    },
    [Theme.media.down('small')]: {
      textAlign: 'center',
    },
  },
  subtitle: {
    [Theme.media.down('small')]: {
      textAlign: 'center',
    },
  },
  getAQuoteButton: {
    maxWidth: Theme.spacing(18),
    [Theme.media.down('mid')]: {
      marginBottom: Theme.spacing(6),
    },
  },
  grid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: `repeat(3, auto)`,
    gridColumnGap: Theme.spacing(10),
    [Theme.media.down('large')]: {
      gridColumnGap: Theme.spacing(5),
    },
    [Theme.media.down('mid')]: {
      gridRowGap: Theme.spacing(10),
      paddingRight: Theme.spacing(4),
    },
    [Theme.media.down('small')]: {
      gridTemplateColumns: 'auto',
      paddingRight: Theme.spacing(0),
      gridRowGap: Theme.spacing(0),
    },
  },
  logo: {
    height: '80px',
    width: '100%',
    [Theme.media.down('mid')]: {
      height: '50px',
    },
    [Theme.media.down('small')]: {
      marginBottom: Theme.spacing(5),
    },
  },
}
