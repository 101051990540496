import { React, Text, View, Page } from '@/lib'
import { Theme } from '@/app'
import { NasaAstronaut } from '@/components'

export const NotFound = () => {
  return (
    <Page center>
      <View style={styles.wrapper}>
        <Text variant={'h3 white'}>Not found</Text>
        <Text variant={'offwhite marginTop:2'}>Oops! The page you're trying to access cannot be found.</Text>
        <NasaAstronaut/>
      </View>
    </Page>

  )
}

const styles = {
  wrapper: {
    minHeight: '100vh',
    marginTop: `calc(${Theme.values.headerMenuHeight} + ${Theme.spacing(4)}px)`,
    ...Theme.flex,
    ...Theme.center,
    flexDirection: 'column',
    width: '100%',
  },
}

export default NotFound
