/* eslint-disable no-console */
import { Logger, Page, React } from '@/lib'
import { onMount, onUpdate } from '@codeleap/common'
import {
  Landing,
  Portfolio,
  Services,
  Pricing,
  FAQ,
  ContactUs,
  PartnerCompanies,
  BlogSlider,
  ClientsTestimonials,
  WhoWeAre,
  Team,
  ServicesInfo,
  DefaultHomepageData,
} from '@/pages'
import {
  FaqItemProps,
  LandingItemProps,
  PricingItemProps,
  ServicesInfoItemProps,
} from '../services/_types'
import { AppStatus } from '@/actions'
import { graphql, useStaticQuery } from 'gatsby'
import { Theme } from '@/app'
import { useLocation } from 'react-use'
import animateScrollTo from 'animated-scroll-to'

const defaultScrollOptions = {
  cancelOnUserAction: true,
  easing: (t) => --t * t * t + 1,
  elementToScroll: null,
  horizontalOffset: 0,
  maxDuration: 12000,
  minDuration: 2500,
  speed: 800,
  verticalOffset: 0,
}

const instaScrollOptions = {
  maxDuration: 0,
  minDuration: 0,
  speed: 0,
}

type BaseHomepageProps = {
  service?: string
  data?: {
    landing: LandingItemProps,
    servicesInfo: ServicesInfoItemProps,
    pricing: PricingItemProps,
    faq: FaqItemProps[],
  }
}

export const ServicesEnum = {
  AI: 'ai',
  NextGen: 'next-gen',
  web: 'web',
  apps: 'mobile',
}

export const BaseHomepage = (props: BaseHomepageProps) => {

  const data = (props.data ? { ...props.data } : DefaultHomepageData) as BaseHomepageProps['data']

  const location = useLocation()

  const { height } = Theme.hooks.size()
  const isMobile = Theme.hooks.down('mid')

  onMount(() => {
    AppStatus.setActivity(null)
    defaultScrollOptions.elementToScroll = window
    // HACK dumb hack because otherwise anchor scroll from other pages doesn't work -.-
  })

  onUpdate(() => {
    if (location.trigger != 'load') {
      scrollToHash()
    }
  }, [location])

  const scrollToHash = (hash = location.hash, options = {}) => {
    const lastChar = location.href.charAt(location.href.length - 1)
    const isHome = location.href.slice(0, -1) == location.origin
    const shouldScrollTop =
      isHome || lastChar == '#' || hash == '#top' || hash == '#home'
    const animationDistanceThreshold = height * 2.5
    if (shouldScrollTop) {
      if (window.scrollY > animationDistanceThreshold) {
        animateScrollTo(0, { ...defaultScrollOptions, ...instaScrollOptions })
      } else {
        animateScrollTo(0, { ...defaultScrollOptions })
      }
    } else if (hash) {
      if (hash == '#portfolio') {
        const [scrollToPos] = getScrollParams(hash, {}, props?.service === ServicesEnum.web || props?.service === ServicesEnum.apps ? 600 : 0)
        // not really sure why this 600 is needed but it is a quick fix :D
        animateScrollTo(scrollToPos, {
          ...defaultScrollOptions,
          ...options,
        })
      } else if (hash == '#servicesInfo') {
        const [scrollToPos] = getScrollParams(hash, { center: !isMobile })
        animateScrollTo(scrollToPos, { ...defaultScrollOptions, ...options })
      } else if (hash == '#partnerCompanies') {
        const [scrollToPos] = getScrollParams(hash, { center: !isMobile })
        animateScrollTo(scrollToPos, { ...defaultScrollOptions, ...options })
      } else if (hash == '#services') {
        const [scrollToPos] = getScrollParams(hash, { center: !isMobile })
        animateScrollTo(scrollToPos, { ...defaultScrollOptions, ...options })
      } else if (hash == '#pricing' && props?.service === ServicesEnum.web || props?.service === ServicesEnum.apps) {
        const [scrollToPos] = getScrollParams(hash, { center: !isMobile }, 180)
        animateScrollTo(scrollToPos, { ...defaultScrollOptions, ...options })
      } else {
        const [scrollToPos, distance] = getScrollParams(hash)
        if (distance < animationDistanceThreshold) {
          options = { ...options, ...defaultScrollOptions }
        }
        if (scrollToPos) {
          const offset = hash === '#contact' ? -50 : 0
          // just a little adjust to center better the form when clicking contact in header nav bar
          animateScrollTo(scrollToPos + offset, {
            ...defaultScrollOptions,
            ...instaScrollOptions,
            ...options,
          })
        }
      }
    }
  }

  function getScrollParams(hash, options, offset = 0) {
    const element = document.getElementById(hash.substring(1))
    let scrollToPos = null
    let distance = null
    try {
      const rect = element.getBoundingClientRect()
      const topDistance =
        rect.top -
        (options?.headerMargin ? Theme.values.maxHeaderMenuHeight : 0)
      const viewportCenterDistance =
        (rect.top + rect.bottom) / 2 -
        height / 2 -
        (options?.headerMargin ? Theme.values.maxHeaderMenuHeight : 0)
      distance = options?.center ? viewportCenterDistance : topDistance
      scrollToPos = distance + window.scrollY + offset
    } catch (err) {
      Logger.warn({ err, location })
    }
    return [scrollToPos, distance]
  }

  const queryData = useStaticQuery(query)
  const blogPostsPreview = queryData.allMarkdownRemark.edges

  Logger.deb.perf('render Homepage', { blogPostsPreview, location })

  const AppsHomepage = () => {
    return (
      <Page fullWidth service={props?.service}>
        <Landing data={data?.landing} service={props?.service}/>
        <ServicesInfo data={data?.servicesInfo}/>
        <Pricing data={data?.pricing} service={props?.service}/>
        <FAQ data={data?.faq}/>
        <Portfolio service={props?.service} />
        <ClientsTestimonials/>
        <WhoWeAre/>
        <Team/>
        <BlogSlider data={blogPostsPreview}/>
        <ContactUs/>
      </Page>
    )
  }

  const WebHomepage = () => {
    return (
      <Page fullWidth service={props?.service}>
        <Landing data={data?.landing} service={props?.service}/>
        <ServicesInfo data={data?.servicesInfo}/>
        <Pricing data={data?.pricing} service={props?.service}/>
        <FAQ data={data?.faq}/>
        <Portfolio service={props?.service} />
        <ClientsTestimonials/>
        <WhoWeAre/>
        <Team/>
        <BlogSlider data={blogPostsPreview}/>
        <ContactUs/>
      </Page>
    )
  }

  const AIHomepage = () => {
    return (
      <Page fullWidth service={props?.service}>
        <Landing data={data?.landing} service={props?.service}/>
        <PartnerCompanies/>
        <ServicesInfo data={data?.servicesInfo}/>
        <Portfolio service={props?.service} data={data?.portfolio}/>
        <FAQ data={data?.faq}/>
        <Pricing data={data?.pricing} service={props?.service}/>
        <ClientsTestimonials/>
        <Team/>
        <BlogSlider data={blogPostsPreview}/>
        <ContactUs/>
      </Page>
    )
  }

  const NextGenHomepage = () => {
    return (
      <Page fullWidth service={props?.service}>
        <Landing data={data?.landing} service={props?.service}/>
        <PartnerCompanies/>
        <ServicesInfo data={data?.servicesInfo}/>
        <Portfolio service={props?.service} data={data?.portfolio}/>
        <FAQ data={data?.faq}/>
        <Pricing data={data?.pricing} service={props?.service}/>
        <ClientsTestimonials/>
        <Team/>
        <BlogSlider data={blogPostsPreview}/>
        <ContactUs/>
      </Page>
    )
  }

  const DefaultHomepage = () => {
    return (
      <Page fullWidth isHomepage>
        <Landing data={data?.landing}/>
        <PartnerCompanies/>
        <Services/>
        <Portfolio service={'default'} data={data?.portfolio}/>
        <ClientsTestimonials/>
        <FAQ data={data?.faq}/>
        <Pricing data={data?.pricing} service={'default'}/>
        <Team/>
        <BlogSlider data={blogPostsPreview}/>
        <ContactUs/>
      </Page>
    )
  }

  switch (props?.service) {
    case ServicesEnum.apps:
      return <AppsHomepage/>
    case ServicesEnum.web:
      return <WebHomepage/>
    case ServicesEnum.NextGen:
      return <NextGenHomepage/>
    case ServicesEnum.AI:
      return <AIHomepage/>
    default:
      return <DefaultHomepage/>
  }

}

export default BaseHomepage

const query = graphql`
  query {
    allMarkdownRemark(sort: { frontmatter: { date: DESC } }) {
      totalCount
      edges {
        node {
          id
          excerpt(pruneLength: 400)
          frontmatter {
            title
            subtitle
            date(formatString: "DD MMMM, YYYY")
            image {
              childImageSharp {
                fluid(maxWidth: 1800, quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            author
          }
          fields {
            slug
          }
          timeToRead
        }
      }
    }
  }
`
