import { Assets } from '@/assets'

export const subtitles = [
  `Fill in this form and we'll get back to you in 24 hours with an estimated quote for your project and next steps to make it happen.`,
]

export const services = [
  {
    text: 'Mobile apps',
    icon: Assets.Services.mobile,
  },
  {
    text: `Websites and web\u00A0apps`,
    icon: Assets.Services.web,
  },
  {
    text: 'Next-gen tech (AI,\u00A0VR,\u00A0IoT, etc)',
    icon: Assets.Services['next-gen'],
  },
  {
    text: 'Something else',
    icon: Assets.Services.mixed,
  },
]

export const needs = [
  {
    text: 'I want to validate a new product idea',
    icon: null,
  },
  {
    text: `Create a new channel for customers to interact with my business`,
    icon: null,
  },
  {
    text: 'Driving up sales',
    icon: null,
  },
  {
    text: 'Reduce business costs',
    icon: null,
  },
  {
    text: 'Other',
    icon: null,
  },
]

export const stages = [
  {
    text: 'I need to build something from scratch',
    icon: null,
  },
  {
    text: `I have the designs and/or wireframes ready`,
    icon: null,
  },
  {
    text: `I already have something built but I'm looking to improve it`,
    icon: null,
  },
]

export const budgets = [
  {
    text: 'Under £10,000',
    icon: null,
  },
  {
    text: 'from £10,000 to 25,000',
    icon: null,
  },
  {
    text: 'from £25,000 to £75,000',
    icon: null,
  },
  {
    text: 'Over £75,000',
    icon: null,
  },
  {
    text: `I prefer not to say`,
    icon: null,
  },
]

export const finishings = [
  {
    text: `Basic MVP (prototype)`,
    icon: null,
  },
  {
    text: `Standard (good) product`,
    icon: null,
  },
  {
    text: 'Premium-quality product',
    icon: null,
  },
]

export const timelines = [
  {
    text: `As soon as possible`,
    icon: null,
  },
  {
    text: `Within a few weeks`,
    icon: null,
  },
  {
    text: 'Within a few months',
    icon: null,
  },
  {
    text: 'Not anytime soon, just checking out the options',
    icon: null,
  },
]

export const contactMethods = [
  {
    text: `Email`,
    icon: null,
  },
  {
    text: `Phone`,
    icon: null,
  },
]
