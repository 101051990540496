import { React, CenterWrapper, View, Text, SEO } from '@/lib'
import { Theme, HTMLStylesDark, license } from '@/app'

export const WebsiteLicenses = () => {

  const renderItem = (key, value) => {
    const type = 'License type(s): ' + value.licenses
    const repo = 'Online repository: ' + value.repository
    const url = 'License URL: ' + value.licenseUrl
    return (
      <View key={key} style={styles.itemWrapper}>
        <Text text={key} variant={'p1 light bold'}/>
        <Text text={type} variant={'p2 lightGrey marginTop:1'}/>
        <Text text={repo} variant={'p2 lightGrey marginTop:1'}/>
        <Text text={url} variant={'p2 lightGrey marginTop:1'}/>
      </View>
    )
  }

  const packagesList = Object.keys(license).map((key) => renderItem(key, license[key]))

  return (
    <View style={styles.wrapper}>
      <SEO
        doNotTrack
        title={'Licenses'}
        description={'This website uses assets and third party open source packages that have their usage herein attributed.'}
      />
      <CenterWrapper style={styles.wrapper} contentContainerStyle={styles.content}>
        <a href={'/'}><h2>CodeLeap</h2></a>
        <h4>Licenses</h4>
        <p>Thank you for visiting our website!</p>
        <p>This website uses assets and third party open source packages that have their usage herein attributed:</p>
        <h5>Media, images and videos:</h5>
        <ul>
          <li><a href={'https://www.freepik.com/'}>Freepik</a></li>
          <li><a href={'https://unsplash.com/'}>Unsplash</a></li>
          <li><a href={'https://www.pexels.com/'}>Pexels</a></li>
        </ul>
        <h5>Open source software:</h5>
        {packagesList}
      </CenterWrapper>
    </View>
  )
}

const styles = {
  wrapper: {
    minHeight: '100vh',
    ...Theme.center,
    ...Theme.flex,
    flexDirection: 'column',
    ...Theme.marginVertical(2),
  },
  content: {
    ...HTMLStylesDark.plainText,
    display: 'flex',
    width: 'auto',
    maxWidth: Theme.values.maxTextContentWidth,
    flexDirection: 'column',
  },
  itemWrapper: {
    paddingTop: Theme.spacing(2),
    marginBottom: Theme.spacing(2),
  },
}

export default WebsiteLicenses
