import { React, Text, Page, SEO, View, Logger } from '@/lib'
import { graphql } from 'gatsby'
import { BlogItem } from './_card'
import { Theme } from '@/app'
import moment from 'moment'

export const Blog = ({ data }) => {

  const posts = data.allMarkdownRemark.edges
  posts.sort((a, b) => moment(a.node.frontmatter.date).isBefore(moment(b.node.frontmatter.date)))
  const content = posts.map(({ node }) => (<BlogItem light node={node} key={node.id}/>))
  Logger.log({ data, posts })

  return (
    <Page whiteBackground>
      <SEO title={'Blog - inside CodeLeap'} description={'Articles about entrepreneurship and building extraordinary products'}/>
      <View style={styles.headerWrapper}>
        <Text variant={'h2 primary'} text={'Inside CodeLeap'}/>
        <Text style={styles.subText} variant={'h5 grey'} text={'Articles about entrepreneurship and building extraordinary products'}/>
      </View>
      <View style={styles.grid}>
        {content}
      </View>
    </Page>
  )
}

export const query = graphql`
query {
  allMarkdownRemark(sort: { frontmatter: { date: DESC } }) {
    totalCount
    edges {
      node {
        id
        excerpt(pruneLength: 400)
        frontmatter {
          title
          subtitle
          date(formatString: "DD MMMM, YYYY")
          image {
            childImageSharp {
              fluid(maxWidth: 800, quality: 70) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          author
        }
        fields {
          slug
        }
        timeToRead
      }
    }
  }
}
`

const gridLrg = Theme.spacing(4)

export default Blog

const styles = {
  headerWrapper: {
    marginTop: Theme.values.headerMenuMargin,
    paddingTop: Theme.spacing(2),
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: `repeat(3, calc(33% - ${gridLrg}px))`,
    gridGap: gridLrg * 3 / (3 - 1),
    marginBottom: Theme.spacing(8),
    [Theme.media.down('mid')]: {
      gridTemplateColumns: 'auto',
      gridGap: Theme.spacing(2),
      marginBottom: Theme.spacing(4),
    },
  },
  subText: {
    marginTop: Theme.spacing(2),
    marginBottom: Theme.spacing(4),
    [Theme.media.down('mid')]: {
      marginTop: Theme.spacing(2),
      marginBottom: Theme.spacing(2),
    },
  },
}
