import { React, Text } from '@/lib'
import { FaqItemProps, LandingItemProps, PricingItemProps, ServicesInfoItemProps } from '../_types'

const landing: LandingItemProps = {
  overview: 'AI/ML  |  GEN-AI  |  COMPUTER VISION  |  NLP',
  title: () => {
    return (
      <>
        <h1>
          <Text variant={'h2 primary'}>
            Award Winning Tech <br/> Development agency
          </Text>
        </h1>
      </>
    )
  },
  typist: [
    'build an AI solution for my business',
    'create an AI-based chatbot',
    'launch a computer vision algorithm for quality control',
    'build an AI to automate manual tasks at my company',
    'develop a conversational AI solution',
  ],
}

const servicesInfo: ServicesInfoItemProps = {
  title: 'Deep expertise, cutting-edge technology and agile operations',
  items: [
    {
      title: 'AI/ML',
      description: 'Empower computers to learn from data, make predictions or decisions, and perform tasks without explicit instructions',
    },
    {
      title: 'Computer vision',
      description: 'Interpret visual information from images or videos, emulating human visual perception with facial and object recognition',
    },
    {
      title: 'Gen-AI',
      description: 'Generate new content, such as images, text or music, by learning patterns and structures from existing data',
    },
    {
      title: 'NLP, Voice & OCR',
      description: 'Automatically interpret human language, with text and voice recognition and text-to-speech solutions',
    },
  ],
}

const pricing: PricingItemProps = {
  project: {
    title: 'Per project',
    description: `Ideal for small and well defined projects, we give you a fixed price for better predictability. 
    A great choice if you know what to do and just want us to build it for you.`,
    items: [
      {
        title: 'MVP',
        description: `We'll help you build the simplest version of your AI project so you can test your idea`,
        range: 'From £10\u00A0000 to £20\u00A0000',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        variants: '',
        items: [
          'Simple AI integrations (no customisations)',
          'Single main feature',
          'Execution time from 3 months',
        ],
      },
      {
        title: 'Standard Project',
        description: 'Medium-complexity AI project, including API integrations with some customisations',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        variants: '',
        range: 'From £20\u00A0000 to £50\u00A0000',
        items: [
          'AI integrations or simple custom-build AI solutions',
          'Multiple core features',
          'Integrations with third-party services',
          'Execution time from 3 to 6 months',
        ],
      },
      {
        title: 'Premium',
        description: 'For those with unconventional ideas or looking for a superb user\u00A0experience',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        variants: 'white',
        range: 'Contact us',
        items: [
          'Custom-build AI solutions by our expert AI/ML team',
          'Multiple core features',
          'Multiple third-party service integrations',
          'Complex business logic and behaviour',
        ],
      },
    ],
  },
  monthly: {
    title: 'Per month',
    description: `Ideal for those who need flexibility to change regarding the project's scope and priorities. 
    Also, a good choice for clients looking to work on complex and long-term projects.`,
    items: [
      {
        title: 'Level 1',
        description: `Perfect to build simple tech products or experiment with an MVP for those on a budget and no rush`,
        range: '£4\u00A0000/mo',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        variants: '',
        items: [
          'Includes 10 work days per month',
          'Full support for project management (CTO service)',
          'Flexible resource usage (frontend, backend, AI/ML teams) according to needs',
          'Senior professional dedicated part-time to your project',
          'Cheapest way to develop an app',
        ],
      },
      {
        title: 'Level 2',
        description: 'Recommended for most standard projects, good and efficient balance of cost and speed',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        variants: '',
        range: '£8\u00A0000/mo',
        items: [
          'Includes 20 work days per month',
          'Full support for project management (CTO service)',
          'Flexible resource usage (frontend, backend, AI/ML teams) according to needs',
          'Senior professional dedicated full-time to your project',
          'Supporting team with junior developers',
        ],
      },
      {
        title: 'Level 3',
        description: 'For highly ambitious projects that require multiple features to be developed simultaneously',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        variants: 'white',
        range: 'Contact us',
        items: [
          'Includes 20+ work days per month',
          'Full support for project management (CTO service) and consultancy',
          'Flexible resource usage (frontend, backend, AI/ML teams) according to needs',
          'Multiple senior developers dedicated to your project plus supporting team',
          'Fastest way to develop complex products',
        ],
      },
    ],
  },
}

const faq: FaqItemProps[] = [
  {
    title: 'How much does an AI project typically cost?',
    content: [
      `Most AI project prices will start from £20,000 to £50,000 for an initial launch version, 
      while more complex and unconventional solutions typically start from £50,000 to £100,000. 
      Our pricing is determined based on the number of development days that is required to develop your project,
      so it can vary greatly depending on the complexity of what you’re looking to build. 
      Please get in touch and we will provide you with an initial price estimate within 24 hours.`,
    ],
  },
  {
    title: 'Do you accept projects that have lower budgets?',
    content: [
      `Sure! Get in touch and we’ll see what we can do to help. When possible, we help our clients simplify the scope of 
      their projects so that we can deliver similar results at much lower development costs (e.g. from £10,000 - £20,000). 
      This is often achieved by utilising third-party integrations and tools already available in the market.`,
    ],
  },
  {
    title: 'Do you accept projects that have lower budgets?',
    content: [
      `Sure! Get in touch and we’ll see what we can do to help. When possible, we help our clients simplify 
      the scope of their projects so that we can deliver similar results at much lower development costs (e.g. from £10,000 - £20,000).`,
    ],
  },
  {
    title: 'What services are included with development?',
    content: [
      `We do everything from UX/UI design and illustrations to development, QA testing and launch.`,
      `This means we also develop the entire user interface that is required to launch your AI project 
      (with some exceptions when an interface already exists).`,
    ],
  },

  {
    title: 'What types of projects does CodeLeap work with?',
    content: [
      `CodeLeap is industry-agnostic and works with a wide range of organisations to deliver on their strategic objectives. 
      Our list of clients include businesses ranging from Fortune 500 companies to early-stage startups, SMEs and high-growth tech 
      companies.`,
    ],
  },
  {
    title: 'Do you work with early stage startups?',
    content: [
      `Yes! We love helping founders bring their ideas to life. In fact, this is our favourite type of project. 
      Our team has vast expertise helping startups grow, monetise, and attract the funding that they need to succeed. 
      We’re proud to say that over 70% of startup founders we worked with successfully raised funding for their projects!`,
    ],
  },
  {
    title: 'What technologies do you use?',
    content: [
      `We employ a variety of technologies based on project specifications, including in-house development by our engineering team 
      and integration of third-party solutions like OpenAI APIs to simplify processes and cut costs where possible. 
      Our primary choice is Python with Django framework, hosted on AWS servers, but often use alternative tech stacks as needed. 
      Feel free to reach out to discuss your project requirements further.`,
    ],
  },
  {
    title: 'After completion, who owns the Intellectual Property (IP) of a project?',
    content: [
      `Our development contracts establish that all Intellectual Property that is created for a project belongs to that client, 
      so clients can later license them, monetise it or sell it as they see fit.`,
    ],
  },
  {
    title: 'What are your payment terms?',
    content: [
      `Payments are usually split over 4 to 6 equal instalments, payable upon CodeLeap delivering on pre-agreed project milestones. 
       Our fees are structured this way to give our clients confidence that they will only need to pay once they see our concrete results.
       The payment schedule for a typical 6-month app development project usually looks like this:`,
      `• Milestone 1: Project start`,
      `• Milestone 2: Completion of UX/UI Designs `,
      `• Milestone 3: Alpha Launch (test launch with limited features)`,
      `• Milestone 4: Beta Launch (test app launch with complete, unpolished features)`,
      `• Milestone 5: Final Launch (App Store launches with complete, polished features)`,
    ],
  },
  {
    title: 'Are there any ongoing costs after launch?',
    content: [
      `After launch, our clients usually choose to continue working with us for ongoing maintenance to continue fixing any problems 
      that may arise and adapt their solution to new software updates and market needs. The maintenance costs varies depending on 
      your project’s needs, usually starting from as little as £400 (+VAT) per month.`,
      `Apart from maintenance costs, you can expect to pay for your solution’s server costs 
      (billed on a pay as you go basis directly by your hosting provider, such as AWS), 
      which typically starts from £40 to £100 (+VAT) per month.`,
    ],
  },
  {
    title: 'Can we sign a Non-Disclosure Agreement (NDA) before an initial conversation?',
    content: [
      `Yes, of course! Please send your NDA to hello@codeleap.co.uk. Alternatively, get in touch and we can send you our standard NDA to you via DocuSign.`,
    ],
  },
  {
    title: 'What is a "development day"?',
    content: [
      `Development day is a concept used by development agencies which represents the number of working days dedicated to a project. 
      At CodeLeap, one development day means one developer working on your project full-time plus unlimited agency support as needed, 
      including: CTO, Project Manager, UX/UI Designer and QA Testers. `,
      `The full-time developer is allocated to your project according to the skills required, switching to other professionals 
      depending on the expertise needed for each phase of the project.`,
    ],
  },
]

const AIHomepageData = {
  landing,
  servicesInfo,
  pricing,
  faq,
}

export default AIHomepageData
