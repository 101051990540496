import { Theme } from '@/app'
import { React, View, Text, CenterWrapper, Tools } from '@/lib'
import { useCallback, useState } from '@codeleap/common'
import Carousel from '@/components/Carousel'
import Slider from 'react-slick'
import Card from './_card'
import { data } from './_data'

export const ClientsTestimonials = () => {

  const { width } = Tools.getMaxContentWidth()
  const small = Theme.hooks.down('small')

  const [selected, setSelected] = useState(0)

  const cardBGColor = Theme.colors.ashGrey
  const selectedCardBGColor = Theme.colors.white

  const renderItem = useCallback(({ item, index }) => (
    <Card
      item={item}
      small={small}
      selected={selected === index}
      cardBGColor={cardBGColor}
      selectedCardBGColor={selectedCardBGColor}
    />
  ), [small, selected])

  const carousel = () => {

    if (small) {

      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 20000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        beforeChange: (oldIndex, nextIndex) => setSelected(nextIndex),
      }

      return (
        <View variant={'flex center'}>
          <View style={{ width: width, maxWidth: width }}>
            <Slider {...settings}>
              {data.map((item, index) => renderItem({ item, index }))}
            </Slider>
          </View>
        </View>
      )
    }

    return (
      <Carousel
        data={data}
        renderItem={renderItem}
        bgColor={cardBGColor}
        bgSelectedColor={selectedCardBGColor}
      />
    )
  }

  return (
    <View variant={'flex column black paddingVertical:20'}>
      <CenterWrapper contentContainerStyle={styles.centerWrapper}>
        <Text variant={`h5 uppercase primary`} text={`Testimonials`}/>
        <Text
          variant={`h2 white marginTop:3 marginBottom:10`}
          style={styles.title}
          text={`What They Say`}
        />
      </CenterWrapper>

      {carousel()}

    </View>
  )
}

const styles = {
  centerWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: Theme.spacing(10),
    [Theme.media.down('small')]: {
      marginBottom: Theme.spacing(7.5),
    },
  },
}

export default ClientsTestimonials
