import { React, View, Text, Page, SEO, Link } from '@/lib'
import { NasaAstronaut, Scientists } from '@/components'
import { Theme, Settings } from '@/app'
import liStyles from './_styles.ts'

export const Careers = () => {
  return (
    <Page center>
      <SEO doNotTrack title={'Careers'} description={"Join our team! We're always looking for awesome talent to join us in our coding adventures."}/>
      <View style={styles.wrapper}>
        <View style={styles.content}>
          <Text variant={'primary h2 marginBottom:2'}>Careers at CodeLeap</Text>
          <Scientists/>
          <Text variant={'lightGrey p1 marginBottom:2'}>
            CodeLeap helps ambitious organisations and entrepreneurs invent, build and scale software products.
          </Text>
          <Text variant={'lightGrey p1 marginBottom:2'}>
            We turn inspirational visions and research into reality, and we currently do it in domains as diverse as education
            or med-tech.
          </Text>
          <Text variant={'lightGrey p1 marginBottom:2'}>
            Although our headquarters is located in London, we are in fact a very international company! Our team are mostly digital
            nomads working from all over the world. Many of us are from Brazil, so portuguese is a common among us, but not necessary.
          </Text>
          <Link
            gaLabel={'Wiki'}
            to={Settings.NOTION_PUBLIC_WIKI}>
            <Text variant={'lightGrey p1 marginTop:2 bold underline'}>Check our Public Handbook page for more information.</Text>
          </Link>
        </View>
        <NasaAstronaut/>
      </View>
    </Page>
  )
}

export default Careers

const styles = {
  wrapper: {
    marginTop: Theme.values.headerMenuMargin,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    maxWidth: Theme.values.maxTextContentWidth,
  },
  content: {
    flex: 1,
    flexDirection: 'column',
    ...liStyles,
  },
}
