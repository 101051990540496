import { Assets } from '@/assets'

export const data = [
  Assets.Logos.Dealerships,
  Assets.Logos.AutoTrader,
  Assets.Logos.Dexters,
  Assets.Logos['aws-gray-logo'],
  Assets.Logos.CedarsSinais,
  Assets.Logos.Jet2Holidays,
  Assets.Logos.DoverStreetMarket,
  Assets.Logos.Lookers,
  Assets.Logos.SweatyBetty,
]

