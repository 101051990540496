/* eslint-disable no-console */
import { View, React } from '@/lib'
import { ShowcaseProps } from '.'
import PortfolioCards from '../Cards'
import { styles } from './styles'
import MobileComponents from '../Mobile'

const DefaultShowcase = (props: ShowcaseProps) => {

  const {
    selected,
    setSelected,
    clear,
  } = props

  return (
    <View style={styles.wrapper} id={'portfolio'}>
      <View style={styles.contentWrapper}>
        <PortfolioCards.Bearable onSelect={() => setSelected('bearable-mobile')}/>
        <PortfolioCards.AWSGirlsTechSeries onSelect={() => setSelected('aws-girls-tech-series-web')}/>
        <PortfolioCards.KiteGeneral onSelect={() => setSelected('kite')}/>
        <View down={'mid'}>{MobileComponents.renderMobileModals({ selected, clear })}</View>
      </View>
    </View>
  )
}

export default DefaultShowcase
