import { React, Page, View, Text, Cookies, Touchable } from '@/lib'
import { useState } from '@codeleap/common'
import { Theme } from '@/app'
import { CheckBox } from '@/components'
import { ABTestManagerCookiesKey } from '@/lib/managers/ABTestManager'

export const ABTestManagerPanel = () => {

  const parsedManager = Cookies.get(ABTestManagerCookiesKey) ? JSON.parse(Cookies.get(ABTestManagerCookiesKey)) : {}
  const parsedManagerEntries = Object.entries(parsedManager)

  const onSwitch = (key, selected, setSelected) => {
    setSelected(!selected)
    const newManager = {
      ...parsedManager,
      [key]: !selected,
    }
    Cookies.set(ABTestManagerCookiesKey, newManager)
  }

  const entries = parsedManagerEntries.map(([key, value]) => {
    const [selected, setSelected] = useState(value)
    return (
      <View key={key} variant={'row justifySpaceBetween fullWidth'}>
        <Text variant={`h4 white marginRight:4 marginBottom:4`} text={key}/>
        <Touchable onPress={() => onSwitch(key, selected, setSelected)}>
          <CheckBox selected={selected}/>
        </Touchable>
      </View>
    )
  })

  return (
    <Page center>
      <View style={styles.wrapper}>
        <Text variant={'h2 white marginBottom:10'} text={`A/B Test Manager Panel`}/>
        <View variant={'column fullWidth'}>
          {entries}
        </View>
      </View>
    </Page>
  )
}

export default ABTestManagerPanel

const styles = {
  wrapper: {
    ...Theme.flex,
    ...Theme.center,
    ...Theme.fullWidth,
    ...Theme.column,
    ...Theme.paddingVertical(20),
  },
}
