import { React, SEO, Page, CenterWrapper } from '@/lib'
import { Scientists } from '@/components'
import { Theme, TextStyles } from '@/app'
import { ContactUs } from '@/pages'

const About = () => {
  return (
    <Page center fullWidth>
      <SEO
        title={'About us'}
        description={`CodeLeap is a London-based software development agency.
       We have years of experience working within leading startups at the forefront of tech innovation in the UK. 
       Wanna build a tech product? Get in touch!`}
      />

      <CenterWrapper style={styles.wrapper} contentContainerStyle={styles.content}>
        <h1>About&nbsp;us</h1>
        <p>Hi there, nice to meet you!</p>
        <p>
          CodeLeap is a London-based software development agency founded by two Computer Science graduates from University College
          London (UCL). We have years of experience working within leading London-based Artificial Intelligence consultancies
          and VC-backed startups, working at the forefront of tech innovation in the UK.
        </p>
        <p>
          We started out developing apps and software solutions for a few people within our network and one project led to another –
          now a few years later and we're a global team of developers working on a variety of digital products with established businesses
          and ambitious entrepreneurs.
        </p>
        <p>Are you ready to start building your digital product?</p>

        <Scientists/>

        <h3>Mission</h3>
        <p>We're here to build awesome products.</p>
        <p className={'inline'}>
          Following advice from some great people in the tech and venture capital industry,
           we decided nothing is more important than to
          <b>make something people want</b>.
          This has since become our main mission.
        </p>
        <p>Our goal is to build products that are not only pretty or interesting, but also useful and that people actively want to use more.</p>

        <h3>Vision</h3>
        <p>We have long plans ahead.</p>
        <p>
          CodeLeap wasn't founded to be just another software agency. Our vision is to be the agency where
          entrepreneurs go to try exciting new ideas. To be the team startup founders trust to build their first product.
        </p>
        <p>
          We want to participate in our clients business in the long run with way more than just tech, but also by providing useful
          insight and growth expertise for top global businesses.
        </p>

        <h3>Values</h3>

        <h6>1. Client satisfaction</h6>
        <p>This is very cliché but incredibly important to remember. It is obvious, but our number one priority is, and will always be client satisfaction.</p>
        <p>
          Although us developers love to work on our intricate engineering solutions, our clients are the project owners and ultimately
          the only ones we need to satisfy at all times. Not Victor (CEO), and not anybody at CodeLeap for that matter, but our only
          clients.
        </p>

        <h6>2. Attention to details</h6>
        <p>Pixel perfect – that's our mantra. Every single part of your product should look and feel neat and polished. </p>
        <p>
          This means we think things through from start to finish. We plan ahead. We execute with care.
          And we aren't done until you're
          also happy with the results.
        </p>

        <h6>3. Make something you're proud of</h6>
        <p>
          Do you know that feeling of excitement when you know you did a f**ing good job? Yeah, that's what we're looking for in every
          single project delivery.</p>
        <p>
          We love what we do. And we feel proud of it. That's also why we don't work on any projects.
           We choose projects we want to display on our portfolio.
        </p>

        <h3>Our&nbsp;team</h3>
        <p>
          Hello again! While our core team is based in London so we can personally meet our clients, some of our best team members are
          tech nomads who work remotely from all over the world.
        </p>
        <p>We're looking forward to meeting you!</p>

      </CenterWrapper>
      <ContactUs/>
    </Page>
  )
}

export default About

const styles = {
  wrapper: {
    marginTop: Theme.values.headerMenuMargin,
    marginBottom: Theme.spacing(8),
    minHeight: '70vh',
    ...Theme.center,
    ...Theme.flex,
    flexDirection: 'column',
  },
  content: {
    width: 'auto',
    maxWidth: Theme.values.maxTextContentWidth,
    flexDirection: 'column',
    '& p': {
      ...TextStyles.text.default,
      ...TextStyles.compatibility.p1,
      ...TextStyles.text.p1,
      ...TextStyles.text.lightGrey,
      marginBottom: Theme.spacing(2),
      marginTop: 0,
    },
    '& h1': {
      ...TextStyles.text.default,
      ...TextStyles.compatibility.h1,
      ...TextStyles.text.h1,
      ...TextStyles.text.primary,
      marginBottom: Theme.spacing(2),
      width: 'min-content',
    },
    '& h3': {
      ...TextStyles.text.default,
      ...TextStyles.compatibility.h3,
      ...TextStyles.text.h3,
      ...TextStyles.text.bold,
      ...TextStyles.text.primary,
      width: 'min-content',
      marginTop: Theme.spacing(4),
      marginBottom: Theme.spacing(2),
    },
    '& h6': {
      ...TextStyles.text.default,
      ...TextStyles.compatibility.h6,
      ...TextStyles.text.h6,
      ...TextStyles.text.light,
      marginTop: Theme.spacing(2),
      marginBottom: Theme.spacing(2),
    },
    '& .inline': {
      ...TextStyles.text.inline,
    },
  },
  teamWrapper: {
    ...Theme.marginVertical(4),
  },
}
