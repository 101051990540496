/* eslint-disable no-console */
import {
  Logger,
  React,
  Tools,
  equals,
} from '@/lib'
import { useState, onMount } from '@codeleap/common'
import { Theme } from '@/app'
import { ServicesEnum } from '../_baseHomepage'
import { Showcases } from '@/components'

export const Portfolio = (props) => {

  const isMobile = Theme.hooks.down('mid')

  const [scrollEventListener, setScrollEventListener] = useState(false)
  const [selected, setSelected] = useState(null)
  const clear = () => setSelected(null)

  const { height } = Theme.hooks.size()

  onMount(() => {
    setUpScrollListener()
  })

  const setUpScrollListener = () => {
    if (isMobile && !scrollEventListener) {
      document.addEventListener('scroll', function () {
        setSelected(null)
        Tools.throttle(() => setSelected(null), 1000)
      })
      setScrollEventListener(true)
    }
  }

  const pageProps = {
    selected,
    setSelected,
    clear,
    isMobile,
    height,
    ...props,
  }

  Logger.deb.perf('render Portfolio', {
    props,
    selected,
    scrollEventListener,
  })

  switch (props?.service) {
    case ServicesEnum.apps:
      return <Showcases.Apps {...pageProps}/>
    case ServicesEnum.web:
      return <Showcases.Web {...pageProps}/>
    case ServicesEnum.NextGen:
      return <Showcases.NextGen {...pageProps}/>
    case ServicesEnum.AI:
      return <Showcases.AI {...pageProps}/>
    default:
      return <Showcases.Default {...pageProps}/>
  }

}

const areEqual = (prevProps, nextProps) => {
  const res = equals(prevProps, nextProps)
  return res
}

export default React.memo(Portfolio, areEqual)
