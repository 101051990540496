import { Assets } from '@/assets'

export type TeamItemProps = {
  name: string
  email?: string
  link?: string
  placeholder?: string
  img_a?: string
  img_b?: string
  role: string
}

export const data: TeamItemProps[] = [
  {
    name: `Lucas`,
    img_a: Assets.Team.Lucas[1],
    img_b: Assets.Team.Lucas[2],
    role: `Backend Engineer`,
  },
  {
    name: `Evair`,
    img_a: Assets.Team.Evair[1],
    img_b: Assets.Team.Evair[2],
    role: `Data Scientist`,
  },
  {
    name: `Victor`,
    img_a: Assets.Team.Victor[1],
    img_b: Assets.Team.Victor[2],
    role: `Co-Founder & CTO`,
  },
  {
    name: `Vivian`,
    img_a: Assets.Team.Vivian[1],
    img_b: Assets.Team.Vivian[2],
    role: `Co-Founder & COO`,
  },
  {
    name: `Leonardo`,
    img_a: Assets.Team.Leo[1],
    img_b: Assets.Team.Leo[2],
    role: `Frontend Engineer `,
  },
  {
    name: `Matt`,
    img_a: Assets.Team.Matheus[1],
    img_b: Assets.Team.Matheus[2],
    role: `Project Manager`,
  },
  {
    name: `Yumi`,
    img_a: Assets.Team.Yumi[1],
    img_b: Assets.Team.Yumi[2],
    role: `Head of Design`,
  },
  {
    name: `Kleber`,
    img_a: Assets.Team.Kleber[1],
    img_b: Assets.Team.Kleber[2],
    role: `Frontend Engineer`,
  },
  {
    name: `Kenzo`,
    img_a: Assets.Team.Kenzo[1],
    img_b: Assets.Team.Kenzo[2],
    role: `Frontend Engineer`,
  },
  {
    name: `Sarah`,
    img_a: Assets.Team.Sarah[1],
    img_b: Assets.Team.Sarah[2],
    role: `UI/UX Designer`,
  },
  {
    name: `Vinny`,
    img_a: Assets.Team.Vini[1],
    img_b: Assets.Team.Vini[2],
    role: `Project Manager`,
  },
  {
    name: `Paulo`,
    img_a: Assets.Team.Paulo[1],
    img_b: Assets.Team.Paulo[2],
    role: `Frontend Engineer`,
  },
  {
    name: `Fernando`,
    img_a: Assets.Team.Fernando[1],
    img_b: Assets.Team.Fernando[2],
    role: `Frontend Engineer`,
  },
  {
    name: `Gabriel`,
    img_a: Assets.Team.Gabriel[1],
    img_b: Assets.Team.Gabriel[2],
    role: `Frontend Engineer`,
  },
  {
    name: `Felipe`,
    img_a: Assets.Team['luiz-felipe'][1],
    img_b: Assets.Team['luiz-felipe'][2],
    role: `Frontend Engineer `,
  },
  {
    name: `Luana`,
    img_a: Assets.Team.Luana[1],
    img_b: Assets.Team.Luana[2],
    role: `UI/UX Designer`,
  },
  {
    name: `Garcez`,
    img_a: Assets.Team.Garcez[1],
    img_b: Assets.Team.Garcez[2],
    role: `Frontend Engineer`,
  },
  {
    name: `Danilo`,
    img_a: Assets.Team.Danilo[1],
    img_b: Assets.Team.Danilo[2],
    role: `Frontend Engineer`,
  },
  {
    name: `Filipe`,
    img_a: Assets.Team.Filipe[1],
    img_b: Assets.Team.Filipe[2],
    role: `Frontend Engineer`,
  },
  {
    name: `Takuya`,
    img_a: Assets.Team.Takuya[1],
    img_b: Assets.Team.Takuya[2],
    role: `Frontend Engineer`,
  },
  {
    name: `Gini`,
    img_a: Assets.Team.Gini[1],
    img_b: Assets.Team.Gini[2],
    role: `Talent Manager`,
  },
  {
    name: `José`,
    img_a: Assets.Team.Jose[1],
    img_b: Assets.Team.Jose[2],
    role: `QA Tester`,
  },
  {
    name: `Join us!`,
    email: `apply@codeleap.co.uk`,
    link: `/careers`,
    role: `See our careers page`,
    placeholder: `?`,
  },
]

