import { React, Text } from '@/lib'
import { DefaultHomepageData } from '@/pages'
import { FaqItemProps, LandingItemProps, PricingItemProps, ServicesInfoItemProps } from '../_types'

const landing: LandingItemProps = {
  overview: 'AR/VR  |  IoT  |  BLOCKCHAIN  |  WEARABLES',
  title: () => {
    return (
      <>
        <h1>
          <Text variant={'h2 primary'}>
            Award Winning Tech <br/> Development agency
          </Text>
        </h1>
      </>
    )
  },
  typist: [
    'build a VR experience for my business',
    'create an IoT monitoring solution',
    'launch a fully-integrated wearable app',
    'build an Augment Reality app for my brand',
    'develop an industry-first tech solution',
  ],
}

const servicesInfo: ServicesInfoItemProps = {
  title: 'Deep expertise, cutting-edge technology and agile operations',
  items: [
    {
      title: 'AR/VR',
      description: 'Craft immersive experiences through cutting-edge AR and VR technologies',
    },
    {
      title: 'IoT',
      description: 'Create interconnected solutions that harness the power of IoT to optimise and enhance processes',
    },
    {
      title: 'Blockchain',
      description: 'Build decentralised blockchain solutions that enhance security, transparency, and efficiency',
    },
    {
      title: 'Wearables',
      description: 'Launch innovative applications tailored for wearable devices, enabling seamless integrations',
    },
  ],
}

const pricing: PricingItemProps = {
  project: {
    title: 'Per project',
    description: `Ideal for small and well defined projects, we give you a fixed price for better predictability. 
    A great choice if you know what to do and just want us to build it for you.`,
    items: [
      {
        title: 'MVP',
        description: `We'll help you build the simplest version of your project so you can test your idea`,
        range: 'From £10\u00A0000 to £20\u00A0000',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        variants: '',
        items: [
          'Simple, custom designs',
          'Single main feature',
          'Execution time from 3 months',
        ],
      },
      {
        title: 'Standard Project',
        description: 'Medium-complexity project, including beautifully crafted custom designs and some integrations',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        variants: '',
        range: 'From £20\u00A0000 to £50\u00A0000',
        items: [
          'Sophisticated, custom designs',
          'Multiple core features',
          'Integrations with third-party services',
          'Execution time from 3 to 6 months',
        ],
      },
      {
        title: 'Premium',
        description: 'For those with unconventional ideas or looking for a superb user\u00A0experience',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        variants: 'white',
        range: 'Contact us',
        items: [
          'Highly-polished multiplatform products',
          'Multiple complex core features',
          'Multiple third-party integrations',
          'Complex business logic and behaviour',
        ],
      },
    ],
  },
  monthly: {
    title: 'Per month',
    description: `Ideal for those who need flexibility to change regarding the project's scope and priorities.
     Also, a good choice for clients looking to work on complex and long-term projects.`,
    items: [
      {
        title: 'Level 1',
        description: `Perfect to build simple tech products or experiment with an MVP for those on a budget and no rush`,
        range: '£4\u00A0000/mo',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        variants: '',
        items: [
          'Includes 10 work days per month',
          'Full support for project management (CTO service)',
          'Flexible resource usage (frontend, backend, AI/ML teams) according to needs',
          'Senior professional dedicated part-time to your project',
          'Cheapest way to develop an app',
        ],
      },
      {
        title: 'Level 2',
        description: 'Recommended for most standard projects, good and efficient balance of cost and speed',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        variants: '',
        range: '£8\u00A0000/mo',
        items: [
          'Includes 20 work days per month',
          'Full support for project management (CTO service)',
          'Flexible resource usage (frontend, backend, AI/ML teams) according to needs',
          'Senior professional dedicated full-time to your project',
          'Supporting team with junior developers',
        ],
      },
      {
        title: 'Level 3',
        description: 'For highly ambitious projects that require multiple features to be developed simultaneously',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        variants: 'white',
        range: 'Contact us',
        items: [
          'Includes 20+ work days per month',
          'Full support for project management (CTO service) and consultancy',
          'Flexible resource usage (frontend, backend, AI/ML teams) according to needs',
          'Multiple senior developers dedicated to your project plus supporting team',
          'Fastest way to develop complex products',
        ],
      },
    ],
  },
}

const faq: FaqItemProps[] = [
  ...DefaultHomepageData.faq,
]

const NextGenHomepageData = {
  landing,
  servicesInfo,
  pricing,
  faq,
}

export default NextGenHomepageData
