import { React, View, Text, Page, SEO, Touchable, Cookies, Logger } from '@/lib'
import { useState } from '@codeleap/common'
import { Theme } from '@/app'
import { CheckBox } from '@/components'

export const Preferences = () => {

  const [optOut, setOptOut] = useState(Cookies.get('Session.isStaff') == 'true')
  Logger.log({ optOut })

  const onSwitch = () => {
    const nextState = !optOut
    Logger.log({ nextState })
    Cookies.set('Session.isStaff', String(nextState))
    setOptOut(nextState)
    window.location.reload()
  }

  const onCrash = () => {
    /* eslint no-undef: 'off' */
    fuuuuuu()
  }

  return (
    <Page center id={'contentWrapper'}>
      <SEO doNotTrack title={'Preferences'} description={'CodeLeap analytics preferences'}/>
      <View style={styles.wrapper}>
        <Text variant={`h2 white`} text={`Preferences`}/>
        <Touchable gaLabel={'Preferences'} gaAction={'CodeLeap staff checkbox'} onPress={onSwitch} style={styles.touchable}>
          <CheckBox selected={optOut}/>
          <Text variant={`h6 white marginLeft:1`} text={`I'm part of CodeLeap staff`}/>
        </Touchable>
        <Touchable gaLabel={'Preferences'} gaAction={'Sentry reporting test'} onPress={onCrash} style={styles.touchable}>
          <Text variant={`h6 white marginLeft:1`} text={`Send crash report`}/>
        </Touchable>
      </View>
    </Page>
  )
}

const styles = {
  wrapper: {
    ...Theme.flex,
    ...Theme.center,
    marginTop: Theme.values.headerMenuMargin,
    flexDirection: 'column',
    position: 'relative',
  },
  innerWrapper: {
    ...Theme.center,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Theme.colors.darkish,
    borderRadius: Theme.values.borderRadius.small,
    padding: Theme.spacing(8),
    marginBottom: '30%',
    [Theme.media.down('mid')]: {
      padding: Theme.spacing(4),
      marginBottom: '50%',
    },
    [Theme.media.down('tiny')]: {
      marginBottom: '80%',
    },
  },
  animation: {
    transform: `scale(1.5)`,
  },
  animationMobile: {
    transform: `scale(1.2)`,
  },
  homeButton: {
    zIndex: 10,
  },
  touchable: {
    ...Theme.flex,
    marginTop: Theme.spacing(2),
    alignItems: 'center',
  },
}

export default Preferences
