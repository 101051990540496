import { React } from '@/lib'
import { BaseHomepage, ServicesEnum } from '@/pages'
import { ServicesData } from '../_index'

export const WebAppsHomepage = () => (
  <BaseHomepage service={ServicesEnum.web} data={ServicesData.web} />
)

export default WebAppsHomepage

