/* eslint-disable no-console */
import { React, View, Text, CenterWrapper, equals, AnalyticsView, Tools, Logger } from '@/lib'
import { BackgroundVideo, ScrollDown, Fade, Reveal, Typist, Clutch } from '@/components'
import { useState } from '@codeleap/common'
import { Theme } from '@/app'
import { LandingItemProps } from '../services/_types'
import { Assets } from '@/assets'
import { ServicesEnum } from './_baseHomepage'

type LandingSceneProps = {
  data: LandingItemProps
  service?: string
}

export const Landing = (props: LandingSceneProps) => {

  const {
    data,
    service,
  } = props

  const [visible, setVisible] = useState(true)

  const initialDelay = 500
  const consecutiveDelay = 150

  const limitedPerformance = Tools.useLimitedPerformance()

  Logger.deb.perf('render Landing', { visible, limitedPerformance })

  const { height } = Theme.hooks.size()

  const isSmall = Theme.hooks.down('small')
  const isTablet = Theme.hooks.down('largeish')

  const isMobile = Theme.hooks.down('mid')

  const sub1Variants = isSmall ? 'p1 thin white marginBottom:4' : 'h6 thin white marginBottom:2'

  let iterator = 1

  const scrollDownHref = service === ServicesEnum.apps || service === ServicesEnum.web ? '#servicesInfo' : '#partnerCompanies'

  return (
    <div>
      <View style={[styles.wrapper, { height: height || '100vh' }]}>
        <Fade delay={0} variant={'opacity'} ready={visible}>
          <div>
            <Reveal variant={'exitOpacity'} offset={height * 0.5} duration={height * 0.4} disabled={limitedPerformance > 0}>
              <div>
                <BackgroundVideo visible={visible} src={Assets.Videos.RocketBWSmall2}/>
              </div>
            </Reveal>
          </div>
        </Fade>
        <View style={[styles.innerWrapper, { height: height || '100vh' }]}>
          <CenterWrapper>
            <Reveal
              variant={'exitOpacity'}
              offset={height * (isTablet ? 0.8 : 0.3)}
              duration={height * 0.55}
              disabled={limitedPerformance > 0 || isMobile}
            >
              <div>
                <View style={styles.textArea}>
                  <AnalyticsView style={styles.contentWrapper} onChangeVisibility={setVisible} gaLabel={'Landing'} reportOnEnter>
                    <Fade delay={initialDelay + (consecutiveDelay * ++iterator)} ready={visible}>
                      <Text variant={sub1Variants} text={data?.overview}/>
                    </Fade>
                    <Fade delay={initialDelay + (consecutiveDelay * ++iterator)} ready={visible}>
                      <View>
                        {data?.title()}
                      </View>
                    </Fade>
                    <Fade delay={initialDelay + (consecutiveDelay * ++iterator)} ready={visible}>
                      <View style={styles.typingBoxWrapper}>
                        <Typist visible={visible} data={data?.typist}/>
                      </View>
                    </Fade>
                    <View variant={'fullWidth'}>
                      <Fade delay={initialDelay + (consecutiveDelay * ++iterator)} ready={visible}>
                        <Clutch/>
                      </Fade>
                    </View>
                  </AnalyticsView>
                </View>
              </div>
            </Reveal>
          </CenterWrapper>
          <View style={styles.scrollDownWrapper}>
            <Reveal variant={'exitOpacity'} offset={height * -0.4} duration={height * 0.5}>
              <div>
                <ScrollDown gaLabel={'Landing ScrollDown'} href={scrollDownHref}/>
              </div>
            </Reveal>
          </View>
        </View>
      </View>
    </div>
  )
}

const areEqual = (prevProps, nextProps) => {
  const res = equals(prevProps, nextProps)
  return res
}

export default React.memo(Landing, areEqual)

const styles = {
  wrapper: {
    ...Theme.center,
    color: 'white',
    [Theme.media.down('largeish')]: {
      paddingBottom: Theme.spacing(5),
    },
  },
  innerWrapper: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  textArea: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
    maxWidth: '100%',
    marginTop: Theme.spacing(20),
    [Theme.media.down('largeish')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  contentWrapper: {
    flex: 1,
    width: 'inherit',
  },
  typingBoxWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: Theme.spacing(4),
    marginBottom: Theme.spacing(8),
    borderRadius: Theme.values.borderRadius.small,
    maxWidth: 650,
    [Theme.media.down('xxlarge')]: {
      marginBottom: Theme.spacing(6),
    },
  },
  scrollDownWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: Theme.spacing(15),
    marginTop: Theme.spacing(15),
    [Theme.media.down('huge')]: {
      marginBottom: Theme.spacing(8),
    },
    [Theme.media.down('mid')]: {
      marginTop: Theme.spacing(10),
    },
  },
}
