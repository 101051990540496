import { React } from '@/lib'
import { BaseHomepage, ServicesEnum } from '@/pages'
import { ServicesData } from '../_index'

export const NextGenHomepage = () => (
  <BaseHomepage service={ServicesEnum.NextGen} data={ServicesData['next-gen']} />
)

export default NextGenHomepage

