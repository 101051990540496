/* eslint-disable no-console */
import {
  View,
  Text,
  CenterWrapper,
  Image,
  equals,
  React,
  Link,
  Touchable,
  AnalyticsView,
  Tools,
  Logger,
} from '@/lib'
import { useState, onUpdate, onMount, useRef } from '@codeleap/common'
import { Theme } from '@/app'
import { Reveal, ScrollDown } from '@/components'
import { styles } from './_styles'
import { BsArrowRight as ArrowRight } from 'react-icons/bs'
import { Tilt } from '@jdion/tilt-react'
import { servicesData } from './_data'

export const Services = () => {

  const [selected, setSelected] = useState(null)
  const [squareSize, setSquareSize] = useState(null)

  const autoplay = useRef()
  const serviceItemRef = useRef()

  const { height, width } = Theme.hooks.size()

  const isSmall = Theme.hooks.down('large')
  const isMobile = Theme.hooks.down('mid')
  const isTiny = Theme.hooks.down('tiny')

  const downLarge = Theme.hooks.down('large')

  const limitedPerformance = Tools.useLimitedPerformance()
  const [scrollEventListener, setScrollEventListener] = useState(false)

  const scrollDownHref = '#portfolio'

  Logger.deb.perf('render Services', {
    selected,
    autoplay,
    height,
    width,
    isSmall,
    limitedPerformance,
  })

  onMount(() => {
    autoplay.current = true
    setUpScrollListener()
  })

  onUpdate(() => {
    const padding = isTiny
      ? Theme.spacing(1)
      : isSmall
        ? Theme.spacing(4)
        : Theme.spacing(8) // ugly hack, see _styles.js itemBackground
    const finalSize = serviceItemRef?.current?.clientWidth - padding
    setUpScrollListener()
    setSquareSize(finalSize)
  }, [width])

  const setUpScrollListener = () => {
    if (isMobile && !scrollEventListener) {
      document.addEventListener('scroll', function () {
        setSelected(null)
        Tools.throttle(() => setSelected(null), 1000)
      })
      setScrollEventListener(true)
    }
  }

  const onHover = (i, e) => {
    setSelected(e ? i : null)
  }

  const renderDesktopItem = (item, key) => {
    const { title, description } = item
    const source = item.image
    const isSelected = key == selected
    const distance = height * 0.1
    const offset = key * distance - height * 0.5
    return (
      <Reveal
        key={key}
        variant={'fadeIn'}
        offset={offset}
        duration={height * 0.8}
        disabled={limitedPerformance == 1}
      >
        <View style={styles.itemWrapper}>
          <Touchable
            gaLabel={'Services'}
            gaAction={title}
            variant={'plain'}
            to={item.to}
          >
            <Tilt options={{ scale: 1.05, max: 4, speed: 2000 }}>
              <View
                ref={serviceItemRef}
                style={[styles.itemBackground, { height: squareSize }]}
                onHover={(e) => onHover(key, e)}
              >
                <Text
                  style={[
                    styles.itemTitle,
                    isSelected ? styles.gone : styles.ungone,
                  ]}
                  text={title}
                />
                <View
                  variant={'flex alignEnd justifyEnd'}
                  style={[isSelected ? styles.gone : styles.ungone]}
                >
                  <Image alt={title} source={source} style={[styles.image]}/>
                </View>
                <View
                  style={[
                    styles.hoverBackground,
                    isSelected && styles.hoverBackgroundActive,
                  ]}
                >
                  <Text
                    style={styles.itemDescription}
                    variant={`white`}
                    text={description}
                  />
                  <Touchable
                    style={styles.seeMoreButtonWrapper}
                    to={item.to}
                    gaAction={`Navigate to ${item.to}`}
                    gaLabel={'Services'}
                  >
                    <Text
                      text={'Learn more'}
                      variant={'uppercase p2 white marginRight:1'}
                    />
                    <ArrowRight color={'white'} size={20}/>
                  </Touchable>
                </View>
              </View>
            </Tilt>
          </Touchable>
        </View>
      </Reveal>
    )
  }

  const renderMobileItem = (item, key) => {
    const { title, description } = item
    const source = item.image
    const isSelected = key == selected
    return (
      <View key={key} style={styles.itemWrapper}>
        <Touchable
          gaLabel={'Services'}
          gaAction={title}
          ref={serviceItemRef}
          style={[styles.itemBackground, { height: squareSize }]}
          onPress={(e) => onHover(key, e)}
        >
          <Text style={styles.itemTitle} text={title}/>
          <View variant={'flex alignEnd justifyEnd'}>
            <Image source={source} style={styles.image}/>
          </View>
          <Touchable
            style={[
              styles.hoverBackground,
              isSelected && styles.hoverBackgroundActive,
            ]}
            onPress={() => onHover(null, null)}
          >
            <View
              style={[
                styles.mobileModal,
                isSelected && styles.mobileModalVisible,
              ]}
            >
              <Text style={styles.itemTitleExpandedMobile} text={title}/>
              <Text style={styles.itemDescription} text={description}/>
              <Touchable
                style={styles.seeMoreButtonWrapper}
                to={item.to}
                gaLabel={'Services'}
                gaAction={`/get-a-quote from ${title}`}
              >
                <Text
                  text={'Learn more'}
                  variant={'uppercase p2 white marginRight:1'}
                />
                <ArrowRight color={'white'} size={20}/>
              </Touchable>
            </View>
          </Touchable>
        </Touchable>
      </View>
    )
  }

  return (
    <AnalyticsView
      id={'services'}
      style={styles.wrapper}
      gaLabel={'Services'}
    >
      <CenterWrapper>
        <View style={styles.innerWrapper}>
          <View variant={'flex center column'} style={styles.topSection}>
            <Reveal
              variant={'unveal fadeInOut'}
              offset={height * (downLarge ? -0.35 : -0.2)}
              duration={height * 1.4}
              disabled={limitedPerformance == 1 || isMobile}
            >
              <View variant={'flex column center'}>
                <View style={styles.sub1Wrapper}>
                  <Text variant={'h2 textCenter primary'}>
                    Deep expertise, cutting-edge  <br/> technology and agile operations
                  </Text>
                </View>
                <Text
                  style={styles.subtitleText}
                  variant={'h6 white textCenter'}
                >
                  We work with a wide range of organisations to deliver on their
                  digital and strategic objectives, <br/> executed with a
                  highly professional & committed service.
                </Text>
              </View>
            </Reveal>
          </View>
          <View style={styles.contentWrapper}>
            <View up={'mid'}>
              <Reveal
                variant={'fadeInOut'}
                duration={height * 1.1}
                disabled={limitedPerformance > 0}
              >
                <View style={styles.grid}>
                  {servicesData.map((i, k) => renderDesktopItem(i, k))}
                </View>
              </Reveal>
            </View>
            <View style={styles.grid} down={'mid'}>
              {servicesData.map((i, k) => renderMobileItem(i, k))}
            </View>
          </View>
          <Reveal
            variant={'fadeInOut'}
            offset={-height * 0.2}
            duration={height * 0.6}
            disabled={limitedPerformance > 0}
          >
            <View variant={'paddingVertical:8'}>
              <Text variant={`thin offwhite inline textCenter`}>
                Have a different tech challenge?{isMobile ? <br/> : ' '}
                <Link
                  gaLabel={'Services'}
                  gaAction={'/#contact from "Contact us for a chat"'}
                  href={'/#contact'}
                  className='underline-offwhite'
                >
                  Contact us for a chat.
                </Link>
              </Text>
            </View>
            <View up={'mid'}>
              <ScrollDown
                color={Theme.colors.offwhite}
                gaLabel={'Services'}
                href={scrollDownHref}
              />
            </View>
          </Reveal>
        </View>
      </CenterWrapper>
    </AnalyticsView>
  )
}

const areEqual = (prevProps, nextProps) => {
  const res = equals(prevProps, nextProps)
  return res
}

export default React.memo(Services, areEqual)
