import { React, View, Text, equals, Touchable, Analytics, AnalyticsView, Tools, Logger } from '@/lib'
import { Theme } from '@/app'
import Slider from 'react-slick'
import '@/app/stylesheets/css/slick.css'
import '@/app/stylesheets/css/slick-theme.css'
import { BsCaretRightFill, BsCaretLeftFill } from 'react-icons/bs'
import { Reveal, ScrollDown } from '@/components'
import testimonialsData from './_data'

/** @jsx jsx */
import { jsx } from '@emotion/react'

export const TestimonialsPreview = () => {

  Logger.deb.perf('render Testimonials')

  const limitedPerformance = Tools.useLimitedPerformance()

  const { height } = Theme.hooks.size()
  const downLarge = Theme.hooks.down('large')

  const renderTestimonial = (item, key) => {
    const { title, description } = item
    return (
      <View key={key}>
        <View style={styles.testimonialWrapper}>
          <View style={styles.testimonialInnerWrapper}>
            <Text variant={'white p1 textCenter'}text={description}/>
            <Text variant={'white h6 semiBold lightGrey textCenter marginTop:3 marginBottom:2 primary'} text={title}/>
          </View>
        </View>
      </View>
    )
  }

  const tweenProps = {
    fade: {
      ease: 'slow(0.1, 0.1, false)',
    },
    opacity: {
      ease: x => ((Math.sin(2 * Math.PI * (x - (1 / 4))) + 1) / 2),
    },
  }

  const quoteParallaxAmount = limitedPerformance > 1 ? '1vmin' : '3vmin'
  const extraStyles = { minHeight: (downLarge ? height * 0.55 : height * 0.65) }

  return (
    <View style={[styles.wrapper, extraStyles]} id={'TestimonialSlider'}>
      <Reveal variant={'fadeInOut'} offset={height * -0.5} duration={height * 1.1} tweenProps={tweenProps} disabled={limitedPerformance > 0}>
        <div>
          <AnalyticsView gaLabel={'TestimonialSlider'} style={styles.contentWrapper}>
            <View up={'mid'}>
              <Reveal variant={'parallaxReverse'} amount={'10vh'} disabled={limitedPerformance > 0}>
                <div>
                  <View style={styles.shadeBackgroundWrapper}><View style={styles.shadeBackground}></View></View>
                </div>
              </Reveal>
            </View>
            <Reveal variant={'parallaxReverse'} amount={quoteParallaxAmount}>
              <View>
                <Text style={[styles.quotationMark, styles.quotationMarkLeft]} text={'“'}/>
              </View>
            </Reveal>
            <Slider {...sliderSettings}>
              {testimonialsData.map((i, k) => renderTestimonial(i, k))}
            </Slider>
            <Reveal variant={'parallaxReverse'} amount={quoteParallaxAmount}>
              <View>
                <Text style={[styles.quotationMark, styles.quotationMarkRight]} text={'”'}/>
              </View>
            </Reveal>
          </AnalyticsView>
        </div>
      </Reveal>
      <View up={'mid'} style={styles.scrollDownWrapper}>
        <Reveal variant={'fadeInOut'} duration={height * 0.2} offset={-height * 0.40} disabled={limitedPerformance > 0}>
          <div>
            <ScrollDown color={Theme.colors.offwhite} gaLabel={'TestimonialSlider'} href={'/#services'}/>
          </div>
        </Reveal>
      </View>
    </View>
  )
}

const iconSize = 40

const NextArrow = (props) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, ...styles.arrowWrapperStyle, ...styles.arrowWrapperStyleRight }}
    >
      <Touchable gaLabel={'TestimonialSlider'} gaAction={'Next'} onPress={onClick} style={styles.arrowWrapperCss}>
        <BsCaretRightFill size={iconSize} color={arrowColor}/>
      </Touchable>
    </div>
  )
}

const PrevArrow = (props) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, ...styles.arrowWrapperStyle, ...styles.arrowWrapperStyleLeft }}
    >
      <Touchable gaLabel={'TestimonialSlider'} gaAction={'Prev'} onPress={onClick} style={styles.arrowWrapperCss}>
        <BsCaretLeftFill size={iconSize} color={arrowColor}/>
      </Touchable>
    </div>
  )
}

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 6000,
  nextArrow: <NextArrow/>,
  prevArrow: <PrevArrow/>,
  adaptiveHeight: false,
  /* eslint react/display-name: 'off' */
  appendDots: dots => <ul>{dots.map((t, i) => <Touchable key={i} gaLabel={'TestimonialSlider'} gaAction={`dot ${i}`}>{t}</Touchable>)}</ul>,
  onSwipe: e => onSwipe(e),
}

const onSwipe = (e) => {
  const params = {
    label: 'TestimonialSlider',
    action: e,
  }
  Analytics.swipe(params)
}

const arrowColor = '#888'

const styles = {
  wrapper: {
    ...Theme.center,
    display: 'flex',
    position: 'relative',
    [Theme.media.down('mid')]: {
      marginTop: Theme.spacing(12),
      marginBottom: Theme.spacing(12),
    },
  },
  contentWrapper: {
    flex: 1,
    display: 'block',
    width: '100vw',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: `min(90%, ${Theme.values.maxContentWidth}px)`,
    position: 'relative',
    ...Theme.center,
  },
  testimonialWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flex: 1,
    ...Theme.center,
  },
  testimonialInnerWrapper: {
    display: 'flex',
    outline: 'none',
    flexDirection: 'column',
    ...Theme.center,
    maxWidth: 'min(70vw, 800px)',
    [Theme.media.down('mid')]: {
      maxWidth: '90%',
    },
  },
  arrowWrapperStyle: {
    zIndex: 1,
    height: '100%',
    width: '10%',
    ...Theme.flex,
    ...Theme.center,
  },
  arrowWrapperStyleLeft: {
    background: 'linear-gradient(0.25turn, black, black, transparent)',
  },
  arrowWrapperStyleRight: {
    background: 'linear-gradient(0.25turn, transparent, black, black)',
  },
  arrowWrapperCss: {
    flex: 1,
    zIndex: 2,
    height: '100%',
    ...Theme.center,
    ...Theme.flex,
    opacity: 0.15,
    transition: '500ms',
    '&:hover': {
      opacity: 1,
      transition: '500ms',
    },
    padding: Theme.spacing(2),
    [Theme.media.down('mid')]: {
      display: 'none',
    },
  },
  quotationMark: {
    color: '#333',
    position: 'absolute',
    fontFamily: 'Playfair-Display',
    fontSize: 200,
    zIndex: 2,
    [Theme.media.down('large')]: {
      fontSize: 180,
    },
    [Theme.media.down('mid')]: {
      fontSize: 160,
    },
  },
  quotationMarkLeft: {
    left: '5vw',
    top: -120,
  },
  quotationMarkRight: {
    right: '5vw',
    top: -80,
    [Theme.media.down('mid')]: {
      top: -60,
    },
  },
  scrollDownWrapper: {
    position: 'absolute',
    bottom: 0,
  },
  shadeBackgroundWrapper: {
    position: 'absolute',
    height: '60vh',
    left: '2vw',
    right: '2vw',
    bottom: '-35vh',
    display: 'flex',
    ...Theme.center,
  },
  shadeBackground: {
    width: '100%',
    height: '100%',
    background: 'radial-gradient(#8481FA33, #8481FA1a, transparent, transparent)',
    boxShadow: 'inset 0 0 20px black',
    [Theme.media.down('mid')]: {
      height: '60%',
      width: '150%',
      marginBottom: '20%',
    },
  },
}

const areEqual = (prevProps, nextProps) => {
  const res = equals(prevProps, nextProps)
  return res
}

export default React.memo(TestimonialsPreview, areEqual)

