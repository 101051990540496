import { React } from '@/lib'
import { BaseHomepage, ServicesEnum } from '@/pages'
import { ServicesData } from '../_index'

export const AIHomepage = () => (
  <BaseHomepage service={ServicesEnum.AI} data={ServicesData.ai} />
)

export default AIHomepage

