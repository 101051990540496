import { React } from '@/lib'
import { BaseHomepage, ServicesEnum } from '@/pages'
import { ServicesData } from '../_index'

export const AppsHomepage = () => (
  <BaseHomepage service={ServicesEnum.apps} data={ServicesData.mobile} />
)

export default AppsHomepage

