import { Theme } from '@/app'

export const styles = {
  wrapper: {
    backgroundColor: Theme.colors.black,
  },
  contentWrapper: {
    marginBottom: Theme.spacing(12),
    [Theme.media.down('mid')]: {
      marginBottom: Theme.spacing(2),
    },
  },
}
