/* eslint-disable no-console */
import { Theme } from '@/app'
import { Carousel } from '@/components'
import { React, CenterWrapper, View, Text, Touchable } from '@/lib'
import { useCallback } from '@codeleap/common'
import Img from 'gatsby-image'
import { Tilt } from '@jdion/tilt-react'

const Arrow = () => {
  return (
    <View style={styles.arrowWrapper}>
      <View style={[styles.arrowLine, styles.arrowHorizontal]}></View>
      <View style={[styles.arrowLine, styles.arrowTop]}></View>
      <View style={[styles.arrowLine, styles.arrowBottom]}></View>
    </View>
  )
}

export const BlogSlider = (props) => {

  const mid = Theme.hooks.down('mid')

  const renderItem = useCallback(({ item, index }) => {
    const meta = item.node.frontmatter

    const Wrapper = mid ? View : Tilt

    return (
      <Wrapper options={{ scale: 1.05, max: 6, speed: 2000 }}>
        <View style={styles.card}>
          <Img
            fluid={meta.image.childImageSharp.fluid}
            style={styles.image}
            alt={meta.title}
          />
          <View style={styles.cardContent} variant={'padding:4 darkest'}>

            <View>
              <Text variant={`h5 medium lightish`} text={meta?.title}/>
              <Text variant={`p1 lightGrey marginTop:3`} text={meta?.subtitle}/>
            </View>

            <Touchable variant={'row'} openNewTab to={`/${item?.node?.fields?.slug}`} style={styles.readMoreWrapper}>
              <Text variant={'p2 primary uppercase'} text={'Read more'}/>
              <Arrow/>
            </Touchable>

          </View>
        </View>
      </Wrapper>
    )
  }, [mid])

  return (
    <CenterWrapper style={styles.wrapper} contentContainerStyle={styles.innerWrapper} centerContent={false} id={'blog'}>
      <View variant={'flex center'}>
        <Text variant={`h2 primary marginBottom:8`} text={`Our blog`}/>
      </View>

      <Carousel data={props.data} renderItem={renderItem}/>

    </CenterWrapper>
  )
}

const styles = {
  wrapper: {
    ...Theme.paddingVertical(10),
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    width: 350,
    borderRadius: Theme.values.borderRadius.small,
    [Theme.media.down('large')]: {
      width: 300,
    },
    [Theme.media.down('small')]: {
      width: 250,
    },
    [Theme.media.down('tiny')]: {
      width: 165,
    },
  },
  cardContent: {
    minHeight: 250,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderBottomLeftRadius: Theme.values.borderRadius.small,
    borderBottomRightRadius: Theme.values.borderRadius.small,
    [Theme.media.down('large')]: {
      minHeight: 200,
    },
    [Theme.media.down('small')]: {
      minHeight: 250,
    },
    [Theme.media.down('tiny')]: {
      minHeight: 400,
    },
  },
  image: {
    borderTopLeftRadius: Theme.values.borderRadius.small,
    borderTopRightRadius: Theme.values.borderRadius.small,
    height: 160,
  },
  arrowWrapper: {
    display: 'block',
    transform: 'scale(0.4)',
    marginLeft: -4,
    marginBottom: 3,
  },
  arrowLine: {
    background: Theme.colors.primary,
    position: 'relative',
    borderRadius: Theme.values.borderRadius.tiny,
  },
  arrowHorizontal: {
    left: 0,
    height: 5,
    width: 60,
    top: 'calc(50% - 2px)',
  },
  arrowTop: {
    background: Theme.colors.primarySolid,
    height: 5,
    width: 22,
    left: 42,
    transform: 'rotate(45deg)',
    top: 'calc(50% - 14px)',
  },
  arrowBottom: {
    top: 'calc(50% - 6px)',
    left: 42,
    transform: 'rotate(-45deg)',
    height: 5,
    width: 22,
  },
  readMoreWrapper: {
    [Theme.media.down('tiny')]: {
      marginTop: Theme.spacing(2),
    },
  },
}

export default BlogSlider
