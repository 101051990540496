import AIHomepageData from './ai/_data'
import AppsHomepageData from './mobile/_data'
import NextGenHomepageData from './next-gen/_data'
import WebAppsHomepageData from './web/_data'

export * from './ai'
export * from './mobile'
export * from './next-gen'
export * from './web'

export const ServicesData = {
  web: WebAppsHomepageData,
  mobile: AppsHomepageData,
  'next-gen': NextGenHomepageData,
  ai: AIHomepageData,
}

export default ServicesData
