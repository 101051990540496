/* eslint no-restricted-imports: 'off' */
import { View, Text, Touchable, Tools, Logger } from '@/lib'
import { useState, useRef, onUpdate } from '@codeleap/common'
import { InputStyles } from '@/app'
import TextareaAutosize from 'react-autosize-textarea'
/** @jsx jsx */
import { jsx } from '@emotion/react'

export type InputProps = {
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onChangeText?: (text: string) => void
  style?: React.CSSProperties
  textStyle?: React.CSSProperties
  label?: string
  labelMsg?: string
  validate?: (value: string) => string | null
  variant?: string
  required?: boolean
  type?: string
  multiline?: boolean
  debug?: boolean
  inputRef?: React.RefObject<HTMLInputElement | HTMLTextAreaElement>
  onFocus?: () => void
  onBlur?: () => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  validatorMessage?: string
  gaLabel?: string
  gaAction?: string
  edited?: boolean
  disabled?: boolean
  reset?: boolean
}

export const Input = (rawProps: InputProps) => {

  const {
    style,
    textStyle,
    value,
    label,
    labelMsg,
    onChange,
    onChangeText,
    validate,
    variant,
    required,
    type,
    multiline,
    debug,
    inputRef,
    onFocus,
    onBlur,
    onKeyDown,
    validatorMessage,
    gaLabel,
    gaAction,
    edited,
    disabled,
    reset,
    ...props
  } = rawProps

  const [hover, setHover] = useState(false)
  const [focus, setFocus] = useState(false)
  const [editedState, setEdited] = useState(edited)

  const input = useRef<HTMLInputElement | HTMLTextAreaElement>(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const text = event.target.value
    if (onChange) onChange(event)
    if (onChangeText) onChangeText(text)
  }

  const handlePress = () => {
    if (inputRef) {
      inputRef.current?.focus()
    } else {
      input.current?.focus()
    }
  }

  const handleBlur = () => {
    if (!editedState && value) setEdited(true)
    setFocus(false)

    if (onBlur) {
      onBlur()
    }
  }

  const handleFocus = () => {
    setFocus(true)
    if (onFocus) {
      onFocus()
    }
  }

  onUpdate(() => {
    if (edited) {
      setEdited(true)
    }
  }, [edited])

  onUpdate(() => {
    setEdited(false)
  }, [reset])

  const validationMsg = rawProps.hasOwnProperty('validate') ? (typeof validate === 'function' ? validate(value) : validate) : null
  const validateError = validationMsg || null

  let animate = 'default'
  if (hover) animate = 'hover'
  if (value) animate = 'populated'
  if (focus) animate = 'focus'
  if (disabled) animate = 'disabled'
  if (validateError && editedState && !disabled) animate = 'invalid'

  const InputElement = multiline ? TextareaAutosize : 'input'

  if (debug) {
    Logger.log({ props, rawProps, animate, validate, validateError, validationMsg })
  }

  const inputVariantValues = Object.keys(InputStyles.wrapper)
  const intersect = variant && Tools.getIntersection(inputVariantValues, variant.split(' '))
  const animationVariant = intersect?.length ? intersect[0] : 'default'

  const wrapperVariants = InputStyles.wrapper[animationVariant]
  const labelVariants = InputStyles.label[animationVariant]
  const staticStyles = InputStyles.staticStyles[animationVariant]

  const labelText = !labelMsg && `${label}${required ? '*' : ''}`
  const hasLabel = labelMsg || label

  const wrapperAnimation = wrapperVariants && wrapperVariants[animate]
  const labelAnimation = labelVariants && labelVariants[animate]

  return (
    <View style={staticStyles.wrapper} variant={variant} debug={debug}>
      <Touchable
        style={[staticStyles.innerWrapper, wrapperAnimation, style]}
        onHover={setHover}
        onPress={() => handlePress()}
        pointerEvents={'auto'}
        variant={'column'}
        gaLabel={gaLabel}
        disabled={disabled}
        gaAction={gaAction || labelText}
      >
        {hasLabel && (
          <View variant={'fullWidth'} style={labelAnimation}>
            <Text style={staticStyles.label} msg={labelMsg} text={labelText} />
          </View>
        )}
        <InputElement
          ref={inputRef || input}
          type={type || 'text'}
          style={{ ...staticStyles.input, ...textStyle }}
          onChange={text => handleChange(text)}
          value={value}
          disabled={disabled}
          onFocus={() => handleFocus()}
          onBlur={handleBlur}
          onKeyDown={onKeyDown}
          rows={4}
          {...props}
        />
      </Touchable>
      {animate === 'invalid' && <Text style={staticStyles.validatorText} text={validatorMessage || validateError} />}
    </View>
  )
}

export default Input
