import { React, View, Text, Image } from '@/lib'
import { Theme } from '@/app'
import { TestimonialItemProps } from './_data'

type CardProps = {
  item: TestimonialItemProps
  small: boolean
  selected: boolean
  cardBGColor: string
  selectedCardBGColor: string
}

const Card = (props: CardProps) => {

  const {
    item,
    small,
    selected,
    selectedCardBGColor,
    cardBGColor
  } = props

  return (
    <>
      <View style={[styles.card, small && { backgroundColor: selected ? selectedCardBGColor : cardBGColor }]}>
        <View variant={'row alignCenter marginBottom:2'}>
          <Image src={item?.logo} style={styles.logo} alt={`${item?.title} logo`}/>
          <View variant={'marginLeft:2'}>
            <Text variant={`p1 bold `} text={item?.app}/>
            <Text variant={`p2 charcoalGrey`} style={styles.title} text={item?.title}/>
          </View>
        </View>
        <Text variant={`p1 charcoalGrey`} text={item?.description}/>
      </View>
    </>
  )

}

const LOGO_SIZE = 40

export default Card

const styles = {
  card: {
    minHeight: 320,
    width: 370,
    padding: Theme.spacing(5),
    borderRadius: Theme.values.borderRadius.medium,
    [Theme.media.down('xxlarge')]: {
      padding: Theme.spacing(4),
      minHeight: 270,
    },
    [Theme.media.down('large')]: {
      padding: Theme.spacing(3),
    },
    [Theme.media.down('small')]: {
      boxSizing: 'border-box',
      marginBottom: Theme.spacing(4),
      width: '95%',
      marginLeft: Theme.spacing(1),
      minHeight: 350,
    },
    [Theme.media.down('tiny')]: {
      minHeight: 400,
    },
  },
  logo: {
    width: LOGO_SIZE,
    height: LOGO_SIZE,
    borderRadius: Theme.values.borderRadius.round,
  },
  title: {
    fontWeigth: '400',
  },
}
