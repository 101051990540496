import { View, React } from '@/lib'
import { ShowcaseProps } from '.'
import { styles } from './styles'
import MobileComponents from '../Mobile'
import PortfolioCards from '../Cards'

const AIShowcase = (props: ShowcaseProps) => {

  const {
    selected,
    setSelected,
    clear,
  } = props

  return (
    <View style={styles.wrapper} id={'portfolio'}>
      <View style={styles.contentWrapper}>
        {/* <PortfolioCards.AutoImaging onSelect={() => setSelected('autoimaging')}/> */}
        <PortfolioCards.CherryMobile onSelect={() => setSelected('cherry')}/>
        <PortfolioCards.Bearable onSelect={() => setSelected('bearable')}/>
        <View down={'mid'}>{MobileComponents.renderMobileModals({ selected, clear })}</View>
      </View>
    </View>
  )
}

export default AIShowcase
