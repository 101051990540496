/* eslint-disable max-lines */
import {
  React,
  View,
  Text,
  Page,
  SEO,
  Input,
  Validate,
  Analytics,
  Touchable,
  Link,
  Button,
  navigate,
  Image,
  Logger,
} from '@/lib'
import { useState, onUpdate, useRef } from '@codeleap/common'
import { Theme, TextStyles, Settings } from '@/app'
import { ContactForm as ContactFormActions } from '@/actions'
import { BackgroundCircle, CheckBox, NasaAstronaut } from '@/components'
import { Tilt } from '@jdion/tilt-react'
import {
  services,
  subtitles,
  stages,
  budgets,
  timelines,
  contactMethods,
  finishings,
} from './_data.ts'

import { faker } from '@faker-js/faker'

const isDev = Settings.IS_DEVELOPMENT

const initialState = {
  name: isDev ? `CodeLeap Tester` : '',
  email: isDev ? 'tester@codeleap.co.uk' : '',
  telephone: isDev ? faker.phone.number() : '',
  message: isDev ? faker.lorem.sentences(4) : '',
  note: isDev ? faker.lorem.sentences(4) : '',
  contactMethod: isDev ? 'Email' : '',
  service: isDev ? 'Mobile apps' : '',
  stage: isDev ? 'I need to build something from scratch' : '',
  budget: isDev ? 'Under £10,000' : '',
  timeline: isDev ? 'Not anytime soon, just checking out the options' : '',
  finishing: isDev ? 'Basic MVP (prototype)' : '',
  receiveMarketing: false,
  status: 'ready',
}

const statusDict = {
  ready: 'SUBMIT',
  sending: 'Sending...',
  done: 'Thank you! We will be in touch shortly',
  invalid: 'Oops! Please check above',
  error: 'Could not send message! Try again?',
}

const ContactPage = (props) => {

  const [state, setState] = useState(initialState)
  const [reset] = useState(0)
  const telInputRef = useRef()
  const messagesInputRef = useRef()
  const notesInputRef = useRef()
  const [contentHeight, setContentHeight] = useState(null)
  const { width } = Theme.hooks.size()
  const isMobile = Theme.hooks.down('mid')

  Logger.log({ props, state })

  onUpdate(() => {
    const finalHeight = document.getElementById('contentWrapper')?.clientHeight
    if (finalHeight && finalHeight != contentHeight) {
      setContentHeight(finalHeight)
    }
  }, [width])

  const validateForm = (test = state) => {
    if (Validate.field(test.name)) return false
    else if (Validate.email(test.email)) return false
    else if (Validate.field(test.message)) return false
    else if (test.service == 'Phone' && Validate.phone(test.telephone)) return false
    else if (Validate.truish(test.service)) return false
    else if (Validate.truish(test.stage)) return false
    else if (Validate.truish(test.finishing)) return false
    else if (Validate.truish(test.budget)) return false
    else if (Validate.truish(test.timeline)) return false
    else return true
  }

  const onChange = (e, key) => {
    const newState = Object.assign({}, state)
    newState[key] = e
    if (status != 'done' && status != 'sending' && validateForm(newState)) {
      newState.status = 'ready'
    }
    setState(newState)
    Analytics.formInput({
      label: 'ContactForm input',
      action: 'key input',
    })
  }

  const submit = () => {
    if (validateForm()) {
      setState({ ...state, status: 'sending' })
      const onSuccess = () => {
        navigate('/get-a-quote/success/')
      }
      const onFailure = () => setState({ ...state, status: 'error' })
      ContactFormActions.submitQuote(state, onSuccess, onFailure)
    } else {
      Logger.log('invalid form data')
      setState({ ...state, status: 'invalid' })
    }
  }

  const blurOnTabPress = (e) => {
    if (e.keyCode == 9) {
      e.preventDefault()
      telInputRef.current && telInputRef.current.blur()
      notesInputRef.current && notesInputRef.current.blur()
      messagesInputRef.current && messagesInputRef.current.blur()
    }
  }

  const invalid = state.status == 'invalid'

  const validatePhone = () => {
    const phoneInvalid =
      state.contactMethod == 'Phone' && Validate.phone(state.telephone)
    return phoneInvalid ? 'err' : null
  }

  const commonInputProps = {
    gaLabel: 'Get a quote form',
    textStyle: isMobile ? styles.inputTextMobile : styles.inputText,
    variant: 'pillDark',
    validate: Validate.field,
    edited: invalid,
    reset: reset,
  }

  const buttonText =
    state.status in statusDict ? statusDict[state.status] : statusDict.ready
  const loading = state.status == 'sending'

  return (
    <Page center id={`contentWrapper`}>
      <SEO
        title={'Contact us'}
        description={
          'Want us to get back to us as fast as possible? This is the best way to reach us.'
        }
      />
      <BackgroundCircle contentHeight={contentHeight}/>
      <View style={styles.wrapper}>
        <Text variant={`h1 white`} text={`Get a quote`}/>
        <Text
          style={styles.headerSubtitleText}
          variant={`white textCenter marginBottom:1 marginTop:8`}
          text={subtitles[0]}
        />
        <Text
          style={styles.headerSubtitleText}
          variant={`white textCenter marginBottom:12 marginTop:1`}
          text={subtitles[1]}
        />
        <View style={styles.card} id={'quoteForm'}>
          <Text style={styles.cardHeaderText} text={'Contact information'}/>
          <View style={styles.grid}>
            <Section title={`What is your name?`}>
              <Input
                {...commonInputProps}
                validatorMessage={'Please provide us a contact name'}
                onChangeText={(e) => onChange(e, 'name')}
                value={state.name}
                gaAction={'name input'}
                name={'name'}
              />
            </Section>

            <Section title={`What is your email?`}>
              <Input
                {...commonInputProps}
                validatorMessage={'Please provide us a valid email address'}
                validate={Validate.email}
                onChangeText={(e) => onChange(e, 'email')}
                value={state.email}
                gaAction={'email input'}
                name={'email'}
              />
            </Section>
            <Section title={`Phone number`}>
              <Input
                {...commonInputProps}
                validatorMessage={'Please provide us a valid phone number'}
                validate={validatePhone}
                edited={validatePhone()}
                onChangeText={(e) => onChange(e, 'telephone')}
                value={state.telephone}
                inputRef={telInputRef}
                onKeyDown={blurOnTabPress}
                gaAction={'telephone input'}
                type='tel'
                name={'phone-number'}
              />
            </Section>
            <Section
              title={`How would you prefer to be contacted?`}
              subtitle={`We won't bother you unnecessarily`}
            >
              <Select
                invalid={invalid}
                options={contactMethods}
                selected={state.contactMethod}
                onChange={(e) => onChange(e, 'contactMethod')}
              />
            </Section>
          </View>

          <Text
            style={[styles.cardHeaderText, styles.cardHeaderTextSpacing]}
            text={'Project details'}
          />
          <View style={styles.grid}>
            <Section
              title={`What type of tech services are you interested in?`}
            >
              <Select
                grid
                invalid={invalid}
                options={services}
                selected={state.service}
                onChange={(e) => onChange(e, 'service')}
              />
            </Section>

            <Section
              title={`What option best describe your project’s stage currently?`}
            >
              <Select
                invalid={invalid}
                options={stages}
                selected={state.stage}
                onChange={(e) => onChange(e, 'stage')}
              />
            </Section>

            <Section
              title={`Please explain your idea`}
              subtitle={
                <>
                  What are the main features? What does it do? What is your
                  business model? Read our article{' '}
                  <Link
                    openNewTab
                    style={styles.link}
                    gaLabel={'Get a quote form'}
                    to={'/bLogger.log/getting-a-quote/'}
                  >
                    How to get a quote?
                  </Link>{' '}
                  to learn more.
                </>
              }
            >
              <Input
                {...commonInputProps}
                multiline={true}
                rows={8}
                textStyle={{ ...styles.inputText, ...styles.multiline }}
                validatorMessage={'Please provide us a description'}
                onKeyDown={blurOnTabPress}
                inputRef={messagesInputRef}
                onChangeText={(e) => onChange(e, 'message')}
                value={state.message}
                gaAction={'message input'}
                name={'message'}
              />
            </Section>

            <Section
              title={`What level of finishing do you expect from your product?`}
              subtitle={`Please keep in mind premium-quality products typically require higher budgets and longer 
                development timelines. We usually recommend starting with an MVP when possible.`}
            >
              <Select
                invalid={invalid}
                options={finishings}
                selected={state.finishing}
                onChange={(e) => onChange(e, 'finishing')}
              />
            </Section>

            <Section title={`Do you have a budget for your project?`}>
              <Select
                invalid={invalid}
                options={budgets}
                selected={state.budget}
                onChange={(e) => onChange(e, 'budget')}
              />
            </Section>

            <Section
              title={`When do you expect to start?`}
              subtitle={`If you have an urgent deadline, let us know in the comment box`}
            >
              <Select
                invalid={invalid}
                options={timelines}
                selected={state.timeline}
                onChange={(e) => onChange(e, 'timeline')}
              />
            </Section>

            <Section
              optional
              title={`Anything else?`}
              subtitle={`Please let us know of anything else that might be relevant to your project, like integrations or additional customization`}
            >
              <Input
                {...commonInputProps}
                edited={false}
                validate={null}
                multiline={true}
                rows={8}
                onKeyDown={blurOnTabPress}
                inputRef={notesInputRef}
                textStyle={{ ...styles.inputText, ...styles.multiline }}
                onChangeText={(e) => onChange(e, 'note')}
                value={state.note}
                gaAction={'note input'}
                name={'extra-message'}
              />
            </Section>

            <Section
              title={`Thanks for getting in touch!`}
              subtitle={`We'll get back to you as soon as possible`}
            >
              <View style={styles.submitWrapper}>
                <View style={styles.receiveMarketingWrapper}>
                  <Touchable
                    gaLabel={'Get a quote form'}
                    gaAction={'Accept marketing email checkbox'}
                    style={styles.receiveMarketingTouchable}
                    onPress={() => onChange(!state.receiveMarketing, 'receiveMarketing')
                    }
                  >
                    <CheckBox selected={state.receiveMarketing}/>
                  </Touchable>
                  <Text variant={'p2 white inline'}>
                    Email me about tech and entrepreneurship news
                  </Text>
                </View>
                <Button
                  gaLabel={'Get a quote form'}
                  gaAction={'Submit button'}
                  variant={'primary fullWidth'}
                  text={buttonText}
                  onPress={submit}
                  loading={loading}
                  style={styles.submitButton}
                />
              </View>
            </Section>
          </View>
        </View>

      </View>
      <View style={styles.astronaut}>
        <NasaAstronaut transparent/>
      </View>
    </Page>
  )
}

const Section = (args) => {
  const { title, subtitle, optional, children } = args
  return (
    <>
      <View style={styles.left}>
        <Text style={styles.sectionTitle}>
          {title}{' '}
          {optional && (
            <Text style={styles.sectionSubtitleOptional} text={'(optional)'}/>
          )}
        </Text>
        <Text style={styles.sectionSubtitle} text={subtitle}/>
      </View>
      <View style={styles.right}>{children}</View>
    </>
  )
}

const Select = (args) => {
  const { options, selected, onChange, grid, invalid, validatorMessage } = args
  const [cardHeight, setCardHeight] = useState(null)
  const { width } = Theme.hooks.size()
  const isMobile = Theme.hooks.down('mid')

  onUpdate(() => {
    if (grid) {
      const finalHeight =
        document.getElementById('optionsWrapper_1')?.clientWidth -
        Theme.spacing(3) // remove padding and margins
      if (finalHeight && finalHeight != cardHeight) {
        setCardHeight(finalHeight)
      }
    }
  }, [width])

  const renderGridItem = (item, key) => {
    const isSelected = item.text == selected
    const onPress = () => onChange(item.text)
    const cardStyles = { height: cardHeight }
    Logger.log({ item })
    return (
      <Tilt key={key} options={{ scale: 1.02, max: 4, speed: 2000 }}>
        <Touchable
          gaLabel={'Get a quote form'}
          gaAction={`select item ${item.text}`}
          onPress={onPress}
          id={`optionsWrapper_${key}`}
          variant={'deep'}
          style={[
            styles.selectItemWrapper,
            styles.selectItemWrapperGrid,
            cardStyles,
          ]}
        >
          <View style={styles.selectGridCardHeader}>
            <View style={styles.checkboxWrapper}>
              <CheckBox selected={isSelected}/>
            </View>
            <Text variant={'white'}>{item.text}</Text>
          </View>
          <View style={styles.selectGridImageWrapper}>
            <Image source={item.icon} style={styles.selectGridImage}/>
          </View>
        </Touchable>
      </Tilt>
    )
  }

  const renderTextItem = (item, key) => {
    const isSelected = item.text == selected
    const onPress = () => onChange(item.text)
    return (
      <Tilt key={key} options={{ scale: 1.02, max: 4, speed: 2000 }}>
        <Touchable
          gaLabel={'Get a quote form'}
          gaAction={`select item ${item.text}`}
          onPress={onPress}
          variant={'deep'}
          style={styles.selectItemWrapper}
        >
          <View style={styles.checkboxWrapper}>
            <CheckBox selected={isSelected}/>
          </View>
          <Text variant={'white'}>{item.text}</Text>
        </Touchable>
      </Tilt>
    )
  }

  return (
    <View style={styles.selectWrapper}>
      {grid && !isMobile ? (
        <View style={styles.selectInnerWrapperGrid}>
          {options.map((i, k) => renderGridItem(i, k))}
        </View>
      ) : (
        options.map((i, k) => renderTextItem(i, k))
      )}
      {invalid && !selected && (
        <Text
          style={styles.invalidSectionText}
          text={validatorMessage || 'Please select an option'}
        />
      )}
    </View>
  )
}

const gridGap = Theme.spacing(4)
const selectGridGap = Theme.spacing(2)

const styles = {
  wrapper: {
    ...Theme.center,
    ...Theme.flex,
    flexDirection: 'column',
    marginTop: Theme.values.headerMenuMargin,
    paddingTop: Theme.spacing(6),
    position: 'relative',
  },
  card: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: Theme.values.maxTextContentWidth,
    width: '100vw',
    backgroundColor: Theme.colors.darkBackground,
    borderRadius: Theme.values.borderRadius.small,
    padding: Theme.spacing(8),
    [Theme.media.up('huge')]: {
      maxWidth: Theme.values.maxTextContentWidth + 120,
    },
    [Theme.media.down('large')]: {
      padding: Theme.spacing(4),
    },
    [Theme.media.down('mid')]: {
      width: 'auto',
      padding: Theme.spacing(2),
    },
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: `calc(40% - ${gridGap}px) calc(60% - ${gridGap}px)`,
    gridColumnGap: gridGap * 2,
    gridRowGap: Theme.spacing(6),
    marginTop: Theme.spacing(4),
    [Theme.media.down('mid')]: {
      display: 'flex',
      flexDirection: 'column',
      gridRowGap: Theme.spacing(2),
    },
  },
  left: {
    [Theme.media.down('mid')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  right: {
    marginBottom: Theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  cardHeaderText: {
    ...TextStyles.text.h3,
    ...TextStyles.text.white,
    ...TextStyles.text.bold,
    [Theme.media.down('mid')]: {
      marginTop: Theme.spacing(1),
    },
  },
  sectionTitle: {
    ...TextStyles.text.h5,
    ...TextStyles.text.light,
    ...TextStyles.text.inline,
    alignItems: 'center',
    display: 'flex',
    [Theme.media.down('mid')]: {
      ...TextStyles.text.block,
    },
  },
  sectionSubtitle: {
    ...TextStyles.text.p1,
    ...TextStyles.text.offwhite,
    ...TextStyles.text.thin,
    ...TextStyles.text.inline,
    marginTop: Theme.spacing(1),
    [Theme.media.down('mid')]: {
      ...TextStyles.text.block,
    },
  },
  sectionSubtitleOptional: {
    ...TextStyles.text.p2,
    ...TextStyles.text.grey,
    ...TextStyles.text.thin,
    ...TextStyles.text.inline,
    marginLeft: Theme.spacing(1),
    [Theme.media.down('mid')]: {
      ...TextStyles.text.block,
    },
  },
  headerSubtitleText: {
    maxWidth: 580,
    [Theme.media.down('mid')]: {
      maxWidth: '90%',
    },
  },
  invalidSectionText: {
    ...TextStyles.text.p2,
    color: 'rgba(255, 20, 0, 1)',
    marginTop: Theme.spacing(1),
    marginLeft: Theme.spacing(3),
  },
  cardHeaderTextSpacing: {
    marginTop: Theme.spacing(8),
    [Theme.media.down('mid')]: {
      marginTop: Theme.spacing(4),
    },
  },
  selectWrapper: {
    flex: 1,
  },
  selectInnerWrapperGrid: {
    display: 'grid',
    gridTemplateColumns: `calc(50% - ${selectGridGap / 2}px) calc(50% - ${
      selectGridGap / 2
    }px)`,
    gridGap: selectGridGap,
    [Theme.media.down('mid')]: {
      gridTemplateColumns: 'auto',
    },
  },
  selectItemWrapper: {
    marginBottom: Theme.spacing(1),
    borderRadius: Theme.values.borderRadius.small,
    transition: '300ms',
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    padding: Theme.spacing(1.5),
    alignItems: 'center',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: Theme.colors.darkest,
    height: '100%',
    '&:hover': {
      background: Theme.colors.darkest,
    },
  },
  selectGridCardHeader: {
    display: 'flex',
  },
  selectItemWrapperGrid: {
    marginBottom: 0,
    alignItems: 'unset',
    display: 'flex',
    flexDirection: 'column',
    height: 216, // preset before overriden by state
  },
  selectGridImageWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    position: 'relative',
    flex: 1,
  },
  selectGridImage: {
    objectFit: 'contain',
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: 100,
    height: 100,
    margin: Theme.spacing(2),
    [Theme.media.down('mid')]: {
      width: 60,
      height: 60,
      margin: Theme.spacing(1),
    },
  },
  checkboxWrapper: {
    marginRight: Theme.spacing(1.5),
  },
  receiveMarketingTouchable: {
    marginLeft: Theme.spacing(1.5) + 2,
    marginRight: Theme.spacing(1.5) + 2,
  },
  receiveMarketingWrapper: {
    ...Theme.flex,
    alignItems: 'center',
  },
  termsLink: {
    ...TextStyles.text.p2,
    ...TextStyles.text.link,
  },
  submitWrapper: {
    width: '100%',
  },
  submitButton: {
    width: '100%',
    marginTop: Theme.spacing(4),
    background: Theme.colors.primary,
  },
  astronaut: {
    margin: Theme.spacing(6),
    [Theme.media.down('mid')]: {
      margin: 0,
    },
  },
  inputText: {
    fontSize: '20px',
  },
  inputTextMobile: {
    fontSize: '18px',
  },
  multiline: {
    minHeight: 216, // NOTE arbitrary, just checked with inspect
  },
  link: {
    fontStyle: 'italic',
    textDecorationColor: 'inherit !important',
    textDecorationStyle: 'solid !important',
    textDecorationLine: 'underline !important',
    '&:hover': {
      color: 'white',
      textDecorationColor: 'white !important',
    },
  },
}

export default ContactPage
