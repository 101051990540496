/* eslint-disable no-mixed-operators */
import { React } from '@/lib'
import { BaseHomepage } from '@/pages'

const Homepage = () => <BaseHomepage/>

export default Homepage

export * from './_homepage/_index'
export * from './about'
export * from './blog'
export * from './careers'
export * from './dev/_index'
export * from './get-a-quote'
export * from './manager'
export * from './preferences'
export * from './services/_index'
export * from './terms/_index'
export * from './404'
