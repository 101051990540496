import {
  View,
  React,
} from '@/lib'
import { ShowcaseProps } from '.'
import PortfolioCards from '../Cards'
import { styles } from './styles'
import MobileComponents from '../Mobile'

const NextGenShowcase = (props: ShowcaseProps) => {

  const {
    selected,
    setSelected,
    clear,
  } = props

  return (
    <View style={styles.wrapper} id={'portfolio'}>
      <View style={styles.contentWrapper}>
        <PortfolioCards.Bearable onSelect={() => setSelected('bearable-mobile')}/>
        <PortfolioCards.BeconMobile onSelect={() => setSelected('becon-mobile')}/>
        <PortfolioCards.AWSCloudroom onSelect={() => setSelected('cloudroom-web')}/>
        <View down={'mid'}>{MobileComponents.renderMobileModals({ selected, clear })}</View>
      </View>
    </View>
  )
}
export default NextGenShowcase
