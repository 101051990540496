import { React, Text } from '@/lib'
import { FaqItemProps, LandingItemProps, PricingItemProps, ServicesInfoItemProps } from '../_types'

const landing: LandingItemProps = {
  overview: 'WEB DEVELOPMENT  |  UX/UI DESIGN  |  END-TO-END SUPPORT',
  title: () => {
    return (
      <>
        <h1>
          <Text variant={'h2 primary'}>
            Award winning web <br/> development agency
          </Text>
        </h1>
      </>
    )
  },
  typist: [
    'build a web app for my startup',
    'create an innovative website to get ahead of competition',
    'launch an web app to engage with customers',
    'build an employee training web application',
    'develop an AI web application',
  ],
}

const servicesInfo: ServicesInfoItemProps = {
  title: 'We are here to help you from idea to launch & beyond',
  items: [
    {
      title: 'Concept & Roadmap',
      description: 'There are always many ideas and ways to go – and we will help you choose the right way',
    },
    {
      title: 'UX/UI Design',
      description: 'Our UX/UI Designers transform ideas into fully fledged website that users love',
    },
    {
      title: 'Development',
      description: 'We develop high-performing websites (web apps), and build all the required backend infrastructure',
    },
    {
      title: 'Support after launch',
      description: 'Have a smooth journey! Our support continues as maintenance and new expansions are needed',
    },
  ],
}

const pricing: PricingItemProps = {
  project: {
    title: 'Per project',
    description: `Ideal for small and well defined projects, we give you a fixed price for better predictability. 
    A great choice if you know what to do and just want us to build it for you.`,
    items: [
      {
        title: 'MVP',
        description: `We'll help you build the simplest version of your web app so you can test\u00A0your\u00A0idea`,
        range: 'From £10\u00A0000 to £20\u00A0000',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        variants: '',
        items: [
          'Optimised for desktop & mobile view',
          'Simple, custom designs',
          'Single main feature',
          'Execution time from 3 months',
        ],
      },
      {
        title: 'Standard Web App',
        description: 'Industry standard web app, including beautifully crafted custom designs and some\u00A0integrations',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        variants: '',
        range: 'From £20\u00A0000 to £50\u00A0000',
        items: [
          'Optimised for desktop & mobile view',
          'Sophisticated, custom designs',
          'Multiple core features',
          'Integrations with third-party services',
          'Execution time from 3 to 6 months',
        ],
      },
      {
        title: 'Premium Web App',
        description: 'For those with unconventional ideas or looking for a superb user\u00A0experience',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        variants: 'white',
        range: 'Contact us',
        items: [
          'Optimised for desktop & mobile view',
          'Highly-polished custom designs & animations',
          'Multiple third-party service integrations',
          'Complex business logic and behaviour',
          'AI, VR or other next-gen technologies',
        ],
      },
    ],
  },
  monthly: {
    title: 'Per month',
    description: `Ideal for those who need flexibility to change regarding the project's scope and priorities. 
    Also, a good choice for clients looking to work on complex and long-term projects.`,
    items: [
      {
        title: 'Level 1',
        description: `Perfect to build simple tech products or experiment with an MVP for those on a budget and no rush`,
        range: '£4\u00A0000/mo',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        variants: '',
        items: [
          'Includes 10 work days per month',
          'Full support for project management (CTO service)',
          'Flexible resource usage (design, frontend & backend) according to needs',
          'Senior professional dedicated part-time to your project',
          'Cheapest way to develop an app',
        ],
      },
      {
        title: 'Level 2',
        description: 'Recommended for most standard projects, good and efficient balance of cost and speed',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        variants: '',
        range: '£8\u00A0000/mo',
        items: [
          'Includes 20 work days per month',
          'Full support for project management (CTO service)',
          'Flexible resource usage (design, frontend & backend) according to needs',
          'Senior professional dedicated full-time to your project',
          'Supporting team with junior developers',
        ],
      },
      {
        title: 'Level 3',
        description: 'For highly ambitious projects that require multiple features to be developed simultaneously',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        variants: 'white',
        range: 'Contact us',
        items: [
          'Includes 20+ work days per month',
          'Full support for project management (CTO service) and consultancy',
          'Multiple senior developers dedicated to your project plus supporting team',
          'Special expertise (Artificial Intelligence, Computer Vision, etc...)',
          'Fastest way to develop complex products',
        ],
      },
    ],
  },
}

const faq: FaqItemProps[] = [
  {
    title: 'How much does a project typically cost?',
    content: [
      `Prices for most websites or web apps start from £20,000 to £50,000 for an initial launch version, 
      while more complex web projects (with multiple core features, integrations and payment processing) typically start from £50,000.
       Our pricing is determined based on the number of development days that is required to develop your project, 
       so it can vary greatly depending on the complexity of what you’re looking to build. 
       Please get in touch and we will provide you with an initial price estimate within 24 hours.`,
    ],
  },
  {
    title: 'What is a “web app” and is it the same as a website?',
    content: [
      `Web applications (web apps) are websites with functionality and interactive elements. Gmail, Facebook, YouTube, Twitter, etc.
       are all web apps that are dynamic, and built for user engagement. 
       Because web apps are extremely customisable and can perform a wide range of functionality, 
       they are generally more difficult to build and require an experienced team of software developers. `,

      `Common websites, on the other hand, are associated with static visual and text content. 
      From a user perspective, the core difference between websites and web applications is function. 
      Websites serve to inform, and web apps serve to help. The content on a website can be viewed, read, or listened to, 
      but the user cannot manipulate it. Conversely, content on web apps is not only viewable but contains interactive elements.`,

      `Unless you’re looking to develop a very simple informational website (a landing page, for example), 
       chances are your project will require the development a web app. 
       Either way, at CodeLeap we can help you with both web products, and our expert team will guide you through the decision-making
       process to ensure the success of your project.`,
    ],
  },
  {
    title: 'What services are included with web development?',
    content: [
      `We do everything from UX/UI design and illustrations to development, QA testing and launch.`,
      `This means we also develop the entire backend infrastructure required to run your services behind the scenes. `,
    ],
  },
  {
    title: 'Do you accept projects that have lower budgets?',
    content: [
      `Sure! Get in touch and we’ll see what we can do to help. When possible, we help our clients simplify the scope of their projects
       so that we can deliver similar results at much lower development costs (e.g. from £10,000 - £20,000).`,
    ],
  },
  {
    title: 'Do you work with early stage startups?',
    content: [
      `Yes! We love helping founders bring their ideas to life. In fact, this is our favourite type of project.
       Our team has vast expertise helping startups grow, monetise, and attract the funding that they need to succeed.
       We’re proud to say that over 70% of startup founders we worked with successfully raised funding for their projects!`,
    ],
  },
  {
    title: 'What technologies do you use?',
    content: [
      `Our preferred tech stack for web development is React. For the backend, we use Python coding language with Django framework,
       hosted on AWS servers. We’re open to working with other tech stacks too, please get in touch to discuss your project.`,
    ],
  },
  {
    title: 'What types of projects does CodeLeap work with?',
    content: [
      `CodeLeap is industry-agnostic and works with a wide range of organisations to deliver on their strategic objectives.
       Our list of clients include businesses ranging from Fortune 500 companies to early-stage startups, SMEs and high-growth
        tech companies.`,
    ],
  },
  {
    title: 'After completion, who owns the Intellectual Property (IP) of a project?',
    content: [
      `Our development contracts establish that all Intellectual Property that is created for a project belongs to that client,
       so clients can later license them, monetise it or sell it as they see fit.`,
    ],
  },
  {
    title: 'What are your payment terms?',
    content: [
      `Payments are usually split over 4 to 6 equal instalments, payable upon CodeLeap delivering on pre-agreed project milestones.
       Our fees are structured this way to give our clients confidence that they will only need to pay once they see our concrete
         results. The payment schedule for a typical 6-month app development project usually looks like this:`,
      `• Milestone 1: Project start`,
      `• Milestone 2: Completion of UX/UI Designs `,
      `• Milestone 3: Alpha Launch (test launch with limited features)`,
      `• Milestone 4: Beta Launch (test app launch with complete, unpolished features)`,
      `• Milestone 5: Final Launch (App Store launches with complete, polished features)`,
    ],
  },
  {
    title: 'Are there any ongoing costs after launch?',
    content: [
      `After launch, our clients usually choose to continue working with us for ongoing maintenance to continue fixing any problems
       that may arise and adapt their web app to new software updates from main internet browsers and devices. The maintenance costs
        varies depending on your project’s needs, starting from as little as £240 (+VAT) per month.`,

      `Apart from maintenance costs, you can expect to pay for your website’s server costs
       (billed on a pay as you go basis directly by your hosting provider, such as AWS), which typically starts from £40 (+VAT) per month.`,
    ],
  },
  {
    title: 'Can we sign a Non-Disclosure Agreement (NDA) before an initial conversation?',
    content: [
      `Yes, of course! Please send your NDA to hello@codeleap.co.uk. Alternatively, get in touch and we can send you our standard NDA to you via DocuSign.`,
    ],
  },
  {
    title: 'What is a "development day"?',
    content: [
      `Development day is a concept used by development agencies which represents the number of working days dedicated to a project.
       At CodeLeap, one development day means one developer working on your project full-time plus unlimited agency support as needed,
       including: CTO, Project Manager, UX/UI Designer and QA Testers. `,
      `The full-time developer is allocated to your project according to the skills required, switching to other professionals depending
       on the expertise needed for each phase of the project.`,
    ],
  },
]

const WebAppsHomepageData = {
  landing,
  servicesInfo,
  pricing,
  faq,
}

export default WebAppsHomepageData
