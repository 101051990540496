/* eslint-disable no-console */
import {
  React,
  View,
  Text,
  CenterWrapper,
  Button,
  Touchable,
  Link,
  equals,
  AnalyticsView,
  Logger,
} from '@/lib'
import { useState, onUpdate } from '@codeleap/common'
import { FaCheck } from 'react-icons/fa'
import { Tilt } from '@jdion/tilt-react'
import { Theme } from '@/app'
import { styles } from './_styles'
import posed from 'react-pose'
import Slider from 'react-slick'

/** @jsx jsx */
import { jsx } from '@emotion/react'

const SectionWrapper = (props) => {
  const { style, contentContainerStyle, children } = props
  return (
    <View style={[style]}>
      <View style={[_styles.innerWrapper, contentContainerStyle]}>
        {children}
      </View>
    </View>
  )
}

const PricingTier = (props) => {

  const extraInnerStyles = { backgroundColor: props.meta.backgroundColor }
  const textVariants = `${props.meta.variants}`
  const iconColor =
    props.meta.variants == 'white' ? 'white' : Theme.colors.primarySolid
  const isMobile = Theme.hooks.down('mid')
  const [hover, onHover] = useState(false)

  const priceButtonText = hover ? 'Get a quote' : props.meta.range

  const items = props.meta.items.map((item, index) => {
    return (
      <View style={styles.tierItem} key={index}>
        <View style={styles.checkIconWrapper}>
          <FaCheck
            color={iconColor}
            size={'18'}
            className={'primary-gradient'}
          />
        </View>
        <Text variant={`${textVariants} p2 marginLeft:2`}>{item}</Text>
      </View>
    )
  })

  const renderTierContentDesktop = () => {
    return (
      <CardAnimation
        id={`pricingTierItem_${props.index}`}
        key={props.index}
        css={[styles.tierWrapper, props.index == 1 && styles.centerTier]}
      >
        <Tilt
          style={styles.tilt}
          options={{ scale: 1.04, max: 4, speed: 2000 }}
        >
          <Touchable
            gaLabel={'Pricing'}
            gaAction={`${props.meta.title} card`}
            variant={'plain'}
            style={[styles.tiltInner, extraInnerStyles]}
          >
            <View style={styles.headerWrapper}>
              <Text
                variant={`h4 semiBold ${
                  textVariants == 'white' ? 'white' : 'primary'
                }`}
                text={props.meta.title}
              />
              <Text
                variant={`p1 dark ${textVariants}`}
                text={props.meta.description}
                style={styles.descriptionText}
              />
            </View>
            <View
              style={[
                styles.separator,
                props.meta.variants == 'white' && styles.separatorWhite,
              ]}
            />
            <View style={styles.tierItemsWrapper}>{items}</View>
            <View style={styles.bottomWrapper}>
              <Button
                onHover={onHover}
                gaLabel={'Pricing'}
                gaAction={`/get-a-quote from ${props.meta.title} card button`}
                to={'/get-a-quote'}
                variant={`fullWidth ${
                  props.meta.variants == 'white' ? 'inverted' : 'primary'
                }`}
                text={priceButtonText}
              />
            </View>
          </Touchable>
        </Tilt>
      </CardAnimation>
    )
  }

  const renderTierContentMobile = () => {
    return (
      <Touchable
        gaLabel={'Pricing'}
        gaAction={`${props.meta.title} card`}
        variant={'plain'}
        style={styles.tilt}
        key={props.index}
        id={`pricingTierItem_${props.index}`}
      >
        <View style={[styles.tiltInner, extraInnerStyles]}>
          <View style={styles.headerWrapper}>
            <Text
              variant={`h4 semiBold ${
                textVariants == 'white' ? 'white' : 'primary'
              }`}
              text={props.meta.title}
            />
            <Text
              variant={`p1 dark ${textVariants}`}
              text={props.meta.description}
              style={styles.descriptionText}
            />
          </View>
          <View
            style={[
              styles.separator,
              props.meta.variants == 'white' && styles.separatorWhite,
            ]}
          />
          <View style={styles.tierItemsWrapper}>{items}</View>
          <View style={styles.bottomWrapper}>
            <Button
              gaLabel={'Pricing'}
              gaAction={`/get-a-quote from ${props.meta.title} card button`}
              to={'/get-a-quote'}
              variant={`fullWidth ${
                props.meta.variants == 'white' ? 'inverted' : 'primary'
              }`}
              text={props.meta.range}
            />
          </View>
        </View>
      </Touchable>
    )
  }
  if (isMobile) {
    return renderTierContentMobile()
  } else {
    return renderTierContentDesktop()
  }
}

export const Pricing = (props) => {

  Logger.deb.perf('render Pricing')

  const [selected, setSelected] = useState(1)
  const [cardHeight, setCardHeight] = useState(null)
  const { width } = Theme.hooks.size()
  const isMobile = Theme.hooks.down('mid')

  onUpdate(() => {
    const finalHeight =
      document.getElementById('pricingTierItem_1')?.clientHeight +
      Theme.spacing(6)
    if (finalHeight && finalHeight != cardHeight) {
      setCardHeight(finalHeight)
    }
  }, [width])

  const renderTierGroup = (data, index) => {
    const isSelected = selected == index
    const tiers = data?.items?.map?.((tier, idx) => (
      <PricingTier meta={tier} key={idx} index={idx}/>
    ))
    if (isMobile) {
      return (
        <View
          style={[
            styles.sliderWrapper,
            isSelected && styles.sliderWrapperVisible,
          ]}
        >
          <Slider style={styles.slider} {...sliderSettings}>
            {tiers}
          </Slider>
        </View>
      )
    } else {
      return (
        <TierAnimation pose={isSelected ? 'show' : 'hide'}>
          <View
            style={[
              styles.tiersWrapper,
              isSelected && styles.tiersWrapperSelected,
            ]}
          >
            {tiers}
          </View>
        </TierAnimation>
      )
    }
  }

  const renderTierButton = (name, index) => {
    const isSelected = selected == index
    return (
      <Touchable
        onPress={() => setSelected(index)}
        gaLabel={'Pricing'}
        gaAction={`${name} button`}
      >
        <View style={styles.tierButtonWrapper}>
          <Text variant={'bold h6'} text={name}/>
          <View
            style={[
              styles.selectedDecoration,
              isSelected && styles.selectedDecorationActive,
            ]}
          >
            <AnalyticsView
              gaLabel={'Pricing'}
              gaAction={name}
              isActive={isSelected}
            />
          </View>
        </View>
      </Touchable>
    )
  }

  const tierContainerWrapperStyles = {
    minHeight: cardHeight,
  }

  const WrapperComponent = isMobile ? View : CenterWrapper

  const isHomepage = props?.service === 'default'

  const contactPath = !isHomepage ? `/services/${props?.service}/#contact` : '#contact'

  return (
    <WrapperComponent style={[styles.wrapper, { paddingTop: Theme.spacing(isHomepage ? 0 : 10) }]} id={'pricing'}>
      <SectionWrapper
        style={styles.sectionWrapper}
        contentContainerStyle={styles.sectionContent}
      >
        <View variant={'flex center column marginBottom:8'}>
          <Text text={`Simple, transparent pricing`} variant={'h2 primary'}/>
        </View>
        <View style={styles.tierSelectWrapper}>
          {renderTierButton(props?.data?.project.title, 1)}
          {renderTierButton(props?.data?.monthly.title, 0)}
          <View style={styles.wideSeparator}/>
        </View>
        <View
          style={[styles.tiersContainerWrapper, tierContainerWrapperStyles]}
        >
          {renderTierGroup(props?.data?.project, 1)}
          {renderTierGroup(props?.data?.monthly, 0)}
        </View>
        <View>
          <View variant={`flex center column marginBottom:6 marginTop:${selected === 1 ? 6 : 10}`}>
            <Text variant={'p1'} text={'Want to know more?'}/>
            <View variant={'row'}>
              <Text variant={'p1'} text={'See our blog article '}/>
              <Link
                gaLabel={'Pricing'}
                gaAction={
                  '/blog/how-we-price/ from "See our blog article How we price!" button'
                }
                to={'/blog/how-we-price/'}
                variant={'space p1'}
                text={'How\u00A0we\u00A0price!'}
              />
            </View>
          </View>
          <View
            variant={'flex center column'}
            style={styles.customOfferWrapper}
          >
            <Text variant={'textCenter inline maxWidth:4 p2'}>
              If you can’t find a plan which meets all your requirements just{' '}
              <Link
                gaLabel={'Pricing'}
                gaAction={'/#contact from "drop us a line" button'}
                href={contactPath}
                className='underline'
              >
                drop us a line
              </Link>{' '}
              and we’ll be happy to prepare a custom offer free of charge.
            </Text>
          </View>
        </View>
      </SectionWrapper>
    </WrapperComponent>
  )
}

const sliderSettings = {
  dots: false,
  adaptiveHeight: false,
  autoplay: false,
  slidesToShow: 1.3,
  infinite: false,
}

const TierAnimation = posed.div({
  show: {
    staggerChildren: 50,
  },
  hide: {
    staggerChildren: 50,
  },
})

const CardAnimation = posed.div({
  show: {
    opacity: 1,
    transform: 'translate3d(0, 0px, 0) scale(1)',
    transition: {
      duration: 500,
      delay: 200,
      ease: 'circOut',
    },
  },
  hide: {
    opacity: 0,
    transform: 'translate3d(0, 30px, 0) scale(0.96)',
    transition: {
      ease: 'circOut',
      duration: 350,
      delay: 0,
    },
  },
})

const areEqual = (prevProps, nextProps) => {
  const res = equals(prevProps, nextProps)
  return res
}

export default React.memo(Pricing, areEqual)

const _styles = {
  innerWrapper: {
    ...Theme.paddingVertical(12),
    [Theme.media.down('mid')]: {
      ...Theme.paddingVertical(8),
    },
  },
}
