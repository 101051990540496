import { View, React } from '@/lib'
import PortfolioCards from '../Cards'
import { ShowcaseProps } from '.'
import { styles } from './styles'
import MobileComponents from '../Mobile'

const AppsShowcase = (props: ShowcaseProps) => {

  const {
    selected,
    setSelected,
    clear,
  } = props

  return (
    <View style={styles.wrapper} id={'portfolio'}>
      <View style={styles.contentWrapper}>
        <PortfolioCards.BeconMobile onSelect={() => setSelected('becon-mobile')}/>
        <PortfolioCards.Bearable onSelect={() => setSelected('bearable-mobile')}/>
        <PortfolioCards.KiteMobile onSelect={() => setSelected('kite-mobile')}/>
        <PortfolioCards.CherryMobile onSelect={() => setSelected('cherry-mobile')}/>
        <PortfolioCards.SkamperMobile onSelect={() => setSelected('skamper-mobile')}/>
        <PortfolioCards.GoQuestMobile onSelect={() => setSelected('go-quest-mobile')}/>
        <View down={'mid'}>{MobileComponents.renderMobileModals({ selected, clear })}</View>
      </View>
    </View>
  )
}

export default AppsShowcase
